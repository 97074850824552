.DialogReportMod_base--1t4Lz {
  width: 565px; }

.DialogReportMod_form--2n6o4 {
  padding: 8px 0 0; }

.DialogReportMod_caption--1CHWx {
  margin: 0 0 14px; }

.DialogReportMod_select--30YHX {
  position: relative;
  z-index: 2; }

.DialogReportMod_tags--pDLiI {
  position: relative;
  z-index: 1;
  margin: 0 0 -10px; }

.DialogReportMod_tag--25I4a {
  display: inline-block;
  margin: 0 10px 10px 0;
  vertical-align: top; }

.DialogReportMod_hr--3lg9F {
  width: 100%;
  height: 1px;
  margin: 17px 0 15px;
  border: none;
  background-color: #2b2b2c; }

.DialogReportMod_fileUpload--1LesH {
  padding: 5px 0 3px; }

.DialogReportMod_fileUploadSummary--6icZf {
  margin: 0 20px 0 0; }

.DialogReportMod_attention--xpT_p {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px; }

.DialogReportMod_fileError--3FEQU {
  margin: 15px 0 0; }

.DialogReportMod_sendError--1vlej {
  margin: 0 0 35px; }
