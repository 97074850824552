.Content_base--fy6bp {
  color: #b8b8a2;
  font-size: 16px;
  line-height: 25px;
  word-wrap: break-word; }
  .Content_base--fy6bp:not(:last-child) {
    margin-bottom: 50px; }
  .Content_base--fy6bp.Content_isEditor--2ciB5 ul,
  .Content_base--fy6bp.Content_isEditor--2ciB5 ol {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; }
    .Content_base--fy6bp.Content_isEditor--2ciB5 ul > li,
    .Content_base--fy6bp.Content_isEditor--2ciB5 ol > li {
      position: relative;
      margin: 0; }
      .Content_base--fy6bp.Content_isEditor--2ciB5 ul > li:not(:last-child),
      .Content_base--fy6bp.Content_isEditor--2ciB5 ol > li:not(:last-child) {
        margin: 0 0 25px; }
  .Content_base--fy6bp.Content_isEditor--2ciB5 ul > li::before {
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #757569;
    content: ''; }
  .Content_base--fy6bp.Content_isEditor--2ciB5 ol > li::before {
    display: inline-block;
    margin-right: 4px;
    color: #b8b8a2;
    font-size: 16px;
    line-height: 25px;
    vertical-align: top;
    content: counter(list) ".";
    counter-increment: list; }
  .Content_base--fy6bp p,
  .Content_base--fy6bp ul,
  .Content_base--fy6bp ol,
  .Content_base--fy6bp strong,
  .Content_base--fy6bp em,
  .Content_base--fy6bp u,
  .Content_base--fy6bp s,
  .Content_base--fy6bp mark,
  .Content_base--fy6bp figcaption {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .Content_base--fy6bp h2,
  .Content_base--fy6bp h3,
  .Content_base--fy6bp h4 {
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .Content_base--fy6bp p,
  .Content_base--fy6bp hr,
  .Content_base--fy6bp figure,
  .Content_base--fy6bp blockquote {
    margin-bottom: 25px;
    padding: 0; }
    .Content_base--fy6bp p:first-child,
    .Content_base--fy6bp hr:first-child,
    .Content_base--fy6bp figure:first-child,
    .Content_base--fy6bp blockquote:first-child {
      margin-top: 0; }
    .Content_base--fy6bp p:last-child,
    .Content_base--fy6bp hr:last-child,
    .Content_base--fy6bp figure:last-child,
    .Content_base--fy6bp blockquote:last-child {
      margin-bottom: 0; }
  .Content_base--fy6bp h2,
  .Content_base--fy6bp h3 {
    margin-bottom: 21px;
    color: #e9e3be;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase; }
    .Content_base--fy6bp h2:first-child,
    .Content_base--fy6bp h3:first-child {
      margin-top: 0; }
    .Content_base--fy6bp h2:last-child,
    .Content_base--fy6bp h3:last-child {
      margin-bottom: 0; }
  .Content_base--fy6bp h4 {
    margin-bottom: 15px;
    color: #fefbed;
    font-size: 17px;
    line-height: 1; }
    .Content_base--fy6bp h4:first-child {
      margin-top: 0; }
    .Content_base--fy6bp h4:last-child {
      margin-bottom: 0; }
  .Content_base--fy6bp strong {
    color: #fefbed;
    font-weight: 700; }
  .Content_base--fy6bp em {
    font-style: italic; }
  .Content_base--fy6bp u {
    text-decoration: underline; }
  .Content_base--fy6bp s {
    text-decoration: line-through; }
  .Content_base--fy6bp ul {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; }
    .Content_base--fy6bp ul > li {
      position: relative;
      padding: 0 0 0 17px; }
      .Content_base--fy6bp ul > li:not(:last-child) {
        margin-bottom: 25px; }
      .Content_base--fy6bp ul > li::before {
        position: absolute;
        top: 11px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #757569;
        content: ''; }
      .Content_base--fy6bp ul > li > ul {
        margin: 11px 0 0 15px; }
        .Content_base--fy6bp ul > li > ul > li:not(:last-child) {
          margin-bottom: 11px; }
  .Content_base--fy6bp ol {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; }
    .Content_base--fy6bp ol > li {
      position: relative;
      margin-top: 51px;
      margin-bottom: 51px; }
      .Content_base--fy6bp ol > li::before {
        display: inline-block;
        margin-right: 4px;
        color: #e9e3be;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        vertical-align: top;
        content: counter(list) ".";
        counter-increment: list; }
      .Content_base--fy6bp ol > li > h2 {
        display: inline-block;
        vertical-align: top; }
  .Content_base--fy6bp mark {
    background: transparent;
    color: #fab81b; }
  .Content_base--fy6bp hr {
    width: 100%;
    height: 1px;
    margin: -10px 0 23px;
    border: none;
    background-color: #2b2b2c; }
  .Content_base--fy6bp figure[class='media'] > div {
    overflow: hidden;
    border-radius: 10px; }
  .Content_base--fy6bp figure[class='image'] > img {
    border-radius: 10px; }
  .Content_base--fy6bp a {
    white-space: normal; }
    .Content_base--fy6bp a strong {
      color: #f9b723; }
    .Content_base--fy6bp a:hover strong {
      color: #ffd428; }
  .Content_base--fy6bp figcaption {
    margin: 10px 0 0; }
  .Content_base--fy6bp img {
    display: inline-block;
    width: 100%;
    vertical-align: top; }
  .Content_base--fy6bp blockquote {
    position: relative;
    padding: 0 0 0 25px;
    border: none;
    font-style: italic; }
    .Content_base--fy6bp blockquote::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background-color: #fefbed;
      content: ''; }

.image-style-side {
  width: 50%;
  margin: 0 0 0 25px;
  float: right; }
