.ActionUnsubscribe_base--3Wdy_ {
  position: relative;
  width: 32px;
  height: 32px;
  border: 1px solid #f9b723;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.4s; }
  .ActionUnsubscribe_base--3Wdy_::before, .ActionUnsubscribe_base--3Wdy_::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .ActionUnsubscribe_base--3Wdy_::before {
    background-image: url(images/action-unsubscribe.svg);
    opacity: 1; }
  .ActionUnsubscribe_base--3Wdy_::after {
    background-image: url(images/action-unsubscribe-hover.svg);
    opacity: 0; }
  .ActionUnsubscribe_base--3Wdy_:hover {
    background-color: #f9b723; }
    .ActionUnsubscribe_base--3Wdy_:hover::before {
      opacity: 0; }
    .ActionUnsubscribe_base--3Wdy_:hover::after {
      opacity: 1; }
