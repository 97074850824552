.EditCoverUpload_base--1kGYp {
  display: flex;
  flex-flow: row nowrap; }

.EditCoverUpload_label--38rGa {
  flex: 0 0 342px;
  box-sizing: border-box;
  margin: 4px 0 0;
  padding: 0 80px 0 0; }

/* stylelint-disable */
_:-ms-lang(x), .EditCoverUpload_label--38rGa {
  flex: 0 0 262px; }

/* stylelint-enable */
.EditCoverUpload_alert--2-Qpx {
  margin: 10px 0 0; }

.EditCoverUpload_field--6wEWy {
  flex: 1 1 100%; }

.EditCoverUpload_fileUploadSummary--AYAKq {
  margin: 0 20px 0 0; }

.EditCoverUpload_cover--2CrhZ {
  position: relative;
  display: block;
  width: 302px;
  height: 170px;
  border-radius: 10px;
  background: url(images/cover.jpg) center no-repeat; }

.EditCoverUpload_image--2g1u_ {
  display: inline-block;
  width: 302px;
  height: 170px;
  border-radius: 10px;
  vertical-align: top; }

.EditCoverUpload_previewRemove--8caMW {
  position: absolute;
  top: -10px;
  right: -10px; }
