.EditScreenshots_base--3X-8K {
  display: flex;
  flex-flow: row nowrap; }

.EditScreenshots_label--2bnYu {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditScreenshots_field--31T2D {
  flex: 1 1 100%; }

.EditScreenshots_fileUploadSummary--327be {
  margin: 0 20px 0 0; }

.EditScreenshots_screenshots--3qs5C {
  margin: 0 -29px 0 0; }

.EditScreenshots_item--1AaAd {
  display: inline-block;
  width: 176px;
  height: 99px;
  margin: 0 29px 30px 0;
  vertical-align: top; }

.EditScreenshots_screenshot--1BnLZ {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  vertical-align: top;
  transition: box-shadow 0.4s; }
  .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH {
    cursor: move; }
    .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH::before, .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 10px;
      opacity: 0;
      content: '';
      transition: opacity 0.4s;
      pointer-events: none; }
    .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH::before {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4); }
    .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH::after {
      z-index: 2;
      background: url(images/edit-screenshots-hover.png) center no-repeat; }
    .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH:hover::before, .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH:hover::after {
      opacity: 1; }
    .dragged .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH {
      box-shadow: 0 0 0 1px #f9b723, 0px 0px 40px 0px rgba(255, 84, 0, 0.3); }
      .dragged .EditScreenshots_screenshot--1BnLZ.EditScreenshots_isDraggable--EmfjH::after {
        opacity: 1; }

.EditScreenshots_image--DoAWX {
  display: inline-block;
  width: 176px;
  height: 99px;
  border-radius: 10px;
  vertical-align: top; }

.EditScreenshots_previewRemove--2acDw {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 3; }

.dragged {
  z-index: 993; }
