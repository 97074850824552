.DialogIEAlert_base--XzrET {
  width: 565px; }

.DialogIEAlert_content--3I03j {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }

.DialogIEAlert_button--36oNb {
  display: inline-block;
  vertical-align: top; }
  .DialogIEAlert_button--36oNb:not(:last-child) {
    margin: 0 20px 0 0; }
