.FilterLanguage_base--wP4rp {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  border-radius: 25px; }
  .FilterLanguage_base--wP4rp.FilterLanguage_isDisabled--WmL0k {
    opacity: .3;
    pointer-events: none; }

.FilterLanguage_select--wpBa6 {
  min-width: 145px; }
