.ModDetails_base--2lYE8 {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  border-radius: 25px;
  background-color: #050505; }

.ModDetails_labels--rc_Ly {
  display: flex;
  margin: 0 0 0 28px; }
  @media all and (max-width: 1365px) {
    .ModDetails_labels--rc_Ly {
      flex-flow: row wrap;
      width: 45%; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetails_labels--rc_Ly {
      flex-flow: row nowrap; } }
  @media all and (min-width: 1408px) {
    .ModDetails_labels--rc_Ly {
      flex-flow: row nowrap; } }

.ModDetails_label--22VdG {
  margin: 1px 0 0;
  color: #a29e87;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap; }
  .ModDetails_label--22VdG mark {
    background: transparent;
    color: #f9f5e1;
    font-weight: 700; }
  .ModDetails_label--22VdG i {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; }
  @media all and (max-width: 1365px) {
    .ModDetails_label--22VdG {
      flex: 0 0 50%;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetails_label--22VdG:not(:last-child) {
      margin-right: 28px; } }
  @media all and (min-width: 1408px) {
    .ModDetails_label--22VdG:not(:last-child) {
      margin-right: 28px; } }

.ModDetails_status--1Z2y7 {
  position: absolute;
  top: 13px;
  right: 30px;
  color: #5eb940;
  font-size: 11px;
  line-height: 25px;
  text-transform: uppercase; }
  .ModDetails_status--1Z2y7::before {
    position: absolute;
    top: 1px;
    left: -25px;
    width: 20px;
    height: 20px;
    background: url(images/details-status.png) center no-repeat;
    content: ''; }

.ModDetails_hidden--2Rtru {
  display: none; }
