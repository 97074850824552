.SearchResult_base--3FfRv {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; }

.SearchResult_head--J6iCv {
  position: relative;
  z-index: 2;
  flex: 0 0 auto; }

.SearchResult_body--1mwhp {
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.SearchResult_filter--39dlF {
  position: relative;
  padding: 48px 0 35px; }

.SearchResult_filterAdditional--A1a3Y {
  position: absolute;
  top: 48px;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start; }

.SearchResult_toggle--1ByTr {
  display: inline-block;
  margin: 0 30px 0 0;
  vertical-align: top; }

.SearchResult_section--2cLi8 {
  position: relative;
  height: 100%; }

.SearchResult_inner--1kUOC:not(:last-child) {
  margin-bottom: -10px; }

.SearchResult_inner--1kUOC:last-child {
  margin-bottom: -50px; }

@media all and (max-width: 1365px) {
  .SearchResult_inner--1kUOC {
    margin-right: -15px;
    margin-left: -15px; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .SearchResult_inner--1kUOC {
    margin-right: -13px;
    margin-left: -13px; } }

@media all and (min-width: 1408px) {
  .SearchResult_inner--1kUOC {
    margin-right: -20px;
    margin-left: -20px; } }

.SearchResult_mod--39tkX {
  display: inline-block;
  vertical-align: top; }
  @media all and (max-width: 1365px) {
    .SearchResult_mod--39tkX {
      margin: 0 15px 50px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .SearchResult_mod--39tkX {
      margin: 0 13px 50px; } }
  @media all and (min-width: 1408px) {
    .SearchResult_mod--39tkX {
      margin: 0 20px 50px; } }

.SearchResult_button--3JVSR {
  text-align: center; }

.SearchResult_muted--3uMTa {
  color: #434343; }
