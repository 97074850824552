.ExternalLink_base--zyHdg {
  text-decoration: none; }
  .ExternalLink_base--zyHdg::after {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 0 0 0 7px;
    background: url(images/external-link.png) center no-repeat;
    vertical-align: baseline;
    opacity: .5;
    content: '';
    transition: opacity 0.4s; }
  .ExternalLink_base--zyHdg:hover::after {
    opacity: 1; }
