.EditState_base--N1Gaj {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.EditState_label--3RtUB {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditState_field---AUsV {
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  white-space: nowrap; }

.EditState_action--1v8_G:not(:last-child) {
  margin: 0 120px 0 0; }
