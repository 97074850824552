.Processing_base--3BD4c {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;
  background: rgba(18, 20, 10, 0.7); }
  .Processing_base--3BD4c.Processing_isVisible--27m10 {
    display: flex; }

.Processing_spinner--1KlsC {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 9px 0 0;
  transform: translate(-50%, -50%); }
