.FilterSelect_base--3_aNz {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 21px;
  vertical-align: top;
  user-select: none; }

.FilterSelect_value--1OlOJ {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 27px 0 12px;
  border-radius: 10px;
  background-color: #e9e3be;
  color: #050505;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.4s; }
  .FilterSelect_value--1OlOJ::after {
    position: absolute;
    top: 50%;
    right: 11px;
    width: 9px;
    height: 6px;
    background-image: url(images/filter-select-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    transform: translateY(-50%); }
  .FilterSelect_value--1OlOJ.FilterSelect_isOpened--6IIea {
    border-radius: 10px 10px 0 0; }
    .FilterSelect_value--1OlOJ.FilterSelect_isOpened--6IIea::after {
      background-position: 0 -6px; }
  .FilterSelect_value--1OlOJ.FilterSelect_isOpened--6IIea, .FilterSelect_value--1OlOJ:hover {
    background-color: #f9f5e1; }
  .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value--1OlOJ {
    position: relative; }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value--1OlOJ::before {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value__ru--2WvaB {
      padding-left: 27px; }
      .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value__ru--2WvaB::before {
        background: url(images/flags.png) 0 0 no-repeat;
        content: ''; }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value__en--34_Vz {
      padding-left: 27px; }
      .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_value__en--34_Vz::before {
        background: url(images/flags.png) 0 -15px no-repeat;
        content: ''; }

.FilterSelect_dropdown--2_UpM {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  border-radius: 0 0 10px 10px;
  background-color: #f9f5e1; }
  .FilterSelect_base--3_aNz.FilterSelect_isOpened--6IIea .FilterSelect_dropdown--2_UpM {
    display: block; }

.FilterSelect_option--1WtdV {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 21px;
  padding: 2px 12px 0;
  border-radius: 10px;
  color: #050505;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.4s; }
  .FilterSelect_option--1WtdV:hover {
    background-color: rgba(18, 18, 18, 0.2); }
  .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_option--1WtdV {
    position: relative;
    padding-left: 27px; }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_option--1WtdV::before {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_option__ru--XvmhM::before {
      background: url(images/flags.png) 0 0 no-repeat;
      content: ''; }
    .FilterSelect_base--3_aNz.FilterSelect_isLanguage--25mkb .FilterSelect_option__en--h9c3M::before {
      background: url(images/flags.png) 0 -15px no-repeat;
      content: ''; }
