.DialogEdgeAlert_base--132MV {
  width: 565px; }

.DialogEdgeAlert_content--FlpJp {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }

.DialogEdgeAlert_button--1qC_U {
  display: inline-block;
  vertical-align: top; }
  .DialogEdgeAlert_button--1qC_U:not(:last-child) {
    margin: 0 20px 0 0; }
