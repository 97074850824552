.Back_base--FEqNr {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 10px 0 10px 60px;
  color: #858271;
  font-size: 11px;
  line-height: 15px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.4s; }
  .Back_base--FEqNr::before, .Back_base--FEqNr::after {
    position: absolute;
    top: 50%;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin: -25px 0 0;
    border: 1px solid rgba(228, 222, 185, 0.2);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; }
  .Back_base--FEqNr::before {
    background-image: url(images/back.png);
    opacity: 1; }
  .Back_base--FEqNr::after {
    background-image: url(images/back-hover.png);
    opacity: 0; }
  .Back_base--FEqNr:hover:not(.Back_isDisabled--1YM-R) {
    color: #e4deb9; }
    .Back_base--FEqNr:hover:not(.Back_isDisabled--1YM-R)::before {
      opacity: 0; }
    .Back_base--FEqNr:hover:not(.Back_isDisabled--1YM-R)::after {
      opacity: 1; }
    .Back_base--FEqNr:hover:not(.Back_isDisabled--1YM-R)::before, .Back_base--FEqNr:hover:not(.Back_isDisabled--1YM-R)::after {
      border: 1px solid #e4deb9;
      background-color: #e4deb9; }
