.TableSorter_base--3OTFg {
  display: inline-block;
  padding: 0 18px 0 0;
  color: #767676;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: top;
  white-space: nowrap; }
  .TableSorter_base--3OTFg::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 11px;
    height: 6px;
    margin: -3px 0 0 0;
    background-image: url(images/table-sorter-arrow.png);
    background-repeat: no-repeat;
    content: normal; }
  .TableSorter_base--3OTFg.TableSorter_isSortable--2uwsf {
    position: relative;
    cursor: pointer; }
  .TableSorter_base--3OTFg.TableSorter_isActive--xSMr8::after {
    content: ''; }
  .TableSorter_base__asc--2YSf4::after {
    background-position: 0 0; }
  .TableSorter_base__desc--3MYvS::after {
    background-position: -11px 0; }
