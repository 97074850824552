.Tag_base--2QBX0 {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 19px;
  padding: 0 15px;
  overflow: hidden;
  border-radius: 10px;
  color: #858271;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer;
  transition: box-shadow 0.4s, color 0.4s; }
  .Tag_base--2QBX0 .Tag_hole--aiHUq::before {
    box-shadow: 0 0 0 2000px #272729; }
  .Tag_base--2QBX0:hover {
    color: #858271; }
    .Tag_base--2QBX0:hover .Tag_hole--aiHUq::before {
      box-shadow: 0 0 0 2000px #3a3a3c; }
  .Tag_base--2QBX0.Tag_isChecked--1I-Ta {
    color: #0e0e0f; }
    .Tag_base--2QBX0.Tag_isChecked--1I-Ta .Tag_hole--aiHUq::before {
      box-shadow: 0 0 0 2000px #f9b723; }
  .Tag_base__light--3Lby_ {
    color: #050505; }
    .Tag_base__light--3Lby_ .Tag_hole--aiHUq::before {
      box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.35); }
    .Tag_base__light--3Lby_.Tag_isChecked--1I-Ta, .Tag_base__light--3Lby_:hover {
      color: #050505; }
      .Tag_base__light--3Lby_.Tag_isChecked--1I-Ta .Tag_hole--aiHUq::before, .Tag_base__light--3Lby_:hover .Tag_hole--aiHUq::before {
        box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.75); }
  .Tag_base__warning--1jI55 {
    color: #858271; }
    .Tag_base__warning--1jI55 .Tag_hole--aiHUq::before {
      box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.08); }
    .Tag_base__warning--1jI55.Tag_isChecked--1I-Ta, .Tag_base__warning--1jI55:hover {
      color: #050505; }
      .Tag_base__warning--1jI55.Tag_isChecked--1I-Ta .Tag_hole--aiHUq::before, .Tag_base__warning--1jI55:hover .Tag_hole--aiHUq::before {
        box-shadow: 0 0 0 2000px #db2d1b; }
  .Tag_base--2QBX0.Tag_isError--3Fn3O {
    box-shadow: 0 0 0 1px #db2d1b; }
  .Tag_base--2QBX0.Tag_isDisabled--oCJuB {
    opacity: .4;
    pointer-events: none; }

.Tag_caption--3m5Zo {
  position: relative;
  z-index: 2; }

.Tag_hole--aiHUq {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden; }
  .Tag_hole--aiHUq::before {
    position: absolute;
    top: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    content: '';
    transition: box-shadow 0.4s, color 0.4s; }
  .Tag_hole__left--Pp6Wv {
    right: 50%;
    left: 0; }
    .Tag_hole__left--Pp6Wv::before {
      left: 6px; }
  .Tag_hole__right--2BjjA {
    right: 0;
    left: 50%; }
    .Tag_hole__right--2BjjA::before {
      right: 6px; }
