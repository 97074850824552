.DialogInformation_base--2f03k {
  width: 565px; }

.DialogInformation_content--3oANP {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }
  .DialogInformation_content--3oANP mark {
    background: none;
    color: #f9f5e1; }

.DialogInformation_hidden--iAItp {
  display: none; }
