.ButtonYellowOutlineSmall_base--1s3lz {
  position: relative;
  height: 32px;
  padding: 0 30px;
  border: 1px solid #f9b723;
  border-radius: 16px;
  background-color: transparent;
  color: #f9b723;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s; }
  .ButtonYellowOutlineSmall_base--1s3lz::before, .ButtonYellowOutlineSmall_base--1s3lz::after {
    position: absolute;
    top: 50%;
    left: 30px;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s;
    transform: translateY(-50%); }
  .ButtonYellowOutlineSmall_base--1s3lz::before {
    opacity: 1; }
  .ButtonYellowOutlineSmall_base--1s3lz::after {
    opacity: 0; }
  .ButtonYellowOutlineSmall_base--1s3lz:focus {
    outline: none; }
  .ButtonYellowOutlineSmall_base--1s3lz:hover {
    background-color: #f9b723;
    color: #000; }
    .ButtonYellowOutlineSmall_base--1s3lz:hover::before {
      opacity: 0; }
    .ButtonYellowOutlineSmall_base--1s3lz:hover::after {
      opacity: 1; }
  .ButtonYellowOutlineSmall_base__plus--1n-R_ {
    padding-left: 48px; }
    .ButtonYellowOutlineSmall_base__plus--1n-R_::before, .ButtonYellowOutlineSmall_base__plus--1n-R_::after {
      width: 10px;
      height: 10px; }
    .ButtonYellowOutlineSmall_base__plus--1n-R_::before {
      background-image: url(images/button-yellow-outline-small-plus.svg); }
    .ButtonYellowOutlineSmall_base__plus--1n-R_::after {
      background-image: url(images/button-yellow-outline-small-plus-hover.svg); }
