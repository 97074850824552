.DialogConfirmationModVersionRemove_base--1Mw3B {
  width: 565px; }

.DialogConfirmationModVersionRemove_content--FYcf4 {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }

.DialogConfirmationModVersionRemove_button--26okM {
  display: inline-block;
  vertical-align: top; }
  .DialogConfirmationModVersionRemove_button--26okM:not(:last-child) {
    margin: 0 20px 0 0; }
