.MainWrapper_base--1tgnT {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;
  padding: 37px 0 0; }

.MainWrapper_nav--rSyrm {
  position: relative;
  z-index: 2;
  flex: 0 0 66px; }

.MainWrapper_body--3I4So {
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.MainWrapper_footer--38sfB {
  position: relative;
  z-index: 2;
  flex: 0 0 66px; }

#common_menu .cm-layout {
  position: relative;
  z-index: 996; }

@media all and (max-width: 1365px) {
  .cm-layout_content {
    width: 1022px;
    padding: 0 28px !important; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .cm-layout_content {
    width: 1366px;
    padding: 0 40px !important; } }

@media all and (min-width: 1408px) {
  .cm-layout_content {
    width: 1408px;
    padding: 0 40px !important; } }
