.ErrorEmptySubscriptions_base--jc1fL {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center; }

.ErrorEmptySubscriptions_message--KV1On {
  margin: 0 0 25px;
  color: #e9e3be;
  font-size: 20px;
  line-height: 28px; }

.ErrorEmptySubscriptions_icon--1MORM {
  display: inline-block;
  width: 286px;
  height: 288px;
  background-image: url(images/error-empty-subscriptions-en.png);
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: top; }
  .ErrorEmptySubscriptions_base__ru--3mRDn .ErrorEmptySubscriptions_icon--1MORM {
    background-image: url(images/error-empty-subscriptions-ru.png); }
  .ErrorEmptySubscriptions_base__en--10huR .ErrorEmptySubscriptions_icon--1MORM {
    background-image: url(images/error-empty-subscriptions-en.png); }
