.Mod_base--NBWHr {
  position: relative;
  width: 302px;
  height: 340px; }

.Mod_inner--2qYnX {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #050505;
  white-space: normal;
  cursor: pointer; }
  .Mod_base--NBWHr.Mod_isDisabled--30eOa .Mod_inner--2qYnX {
    opacity: .3;
    pointer-events: none; }

.Mod_rating--2sjiV {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2; }

.Mod_status--2vGZy {
  position: absolute;
  top: -16px;
  left: -8px;
  z-index: 2; }

.Mod_header--1td0r {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 170px;
  transition: opacity .2s; }
  .Mod_header--1td0r::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    background: linear-gradient(to top, #050505 0%, rgba(5, 5, 5, 0) 100%);
    content: ''; }
  .Mod_base--NBWHr:not(.Mod_isDisabled--30eOa):hover .Mod_header--1td0r {
    opacity: .4; }

.Mod_body--MmAIq {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 170px 20px 0;
  overflow: hidden;
  transition: padding .2s; }
  .Mod_base--NBWHr:not(.Mod_isDisabled--30eOa):hover .Mod_body--MmAIq:not(.Mod_isDownloadDisabled--1NQaZ) {
    padding-top: 115px; }

.Mod_cover--AJ2PF {
  display: block;
  width: 302px;
  height: 170px;
  border-radius: 10px 10px 0 0;
  background: url(images/cover.jpg) center no-repeat; }

.Mod_title--3oaZ8 {
  margin: 0 0 12px;
  color: #e9e3be;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; }

.Mod_clampLines--3hkjV {
  word-wrap: break-word; }

.Mod_summary--3NL74 {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 16px;
  color: #97947f;
  font-size: 13px;
  line-height: 16px; }
  .Mod_summary--3NL74 mark {
    background: none;
    color: #e9e3be; }

.Mod_owner--3wa6I {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.Mod_ownerName--3gKOp {
  color: #e9e3be; }
  .Mod_ownerName--3gKOp.Mod_isClickable--pJ1k1 {
    color: #fab81b;
    transition: color 0.4s; }
    .Mod_ownerName--3gKOp.Mod_isClickable--pJ1k1:hover {
      color: #ffd428; }

.Mod_versions--2wqif {
  margin: 0 5px 0 0; }
  .Mod_versions--2wqif i {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; }

.Mod_button--212l0 {
  opacity: 0;
  transition: opacity .2s; }
  .Mod_base--NBWHr:not(.Mod_isDisabled--30eOa):hover .Mod_button--212l0 {
    opacity: 1;
    transition: opacity .2s; }

.Mod_footer--QbejJ {
  position: relative;
  z-index: 3;
  flex: 0 0 auto;
  padding: 0 20px 16px; }

.Mod_tag--2p4I9 {
  display: inline-block;
  margin: 4px 0;
  vertical-align: top; }
  .Mod_tag--2p4I9:not(:last-child) {
    margin-right: 8px; }

.Mod_viewMod--15n-o {
  position: absolute;
  top: 105px;
  left: 20px;
  z-index: 3; }

.Mod_viewModIcon--94v5j {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #f9b723 url(images/mod-view-icon.svg) no-repeat center;
  cursor: pointer;
  transition: background-color 0.4s; }
  .Mod_viewModIcon--94v5j:hover {
    background-color: #ffd428; }

.Mod_viewModLabel--gF7Lj {
  position: absolute;
  top: 17px;
  left: 50px;
  padding: 0 0 0 10px;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.4s; }
  .Mod_viewModIcon--94v5j:hover + .Mod_viewModLabel--gF7Lj {
    opacity: 1; }

.Mod_hidden--CFLYj {
  display: none; }
