.EditUploadMod_base--298KY {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.EditUploadMod_label--YBK86 {
  flex: 0 0 342px; }

.EditUploadMod_field--tg0dJ {
  flex: 1 1 100%; }

.EditUploadMod_fileUploadSummary--2Jp_l {
  margin: 0 20px 0 0; }
