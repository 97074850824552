.ScrollArea_scrollTrack--26rEt {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.05); }
  .ScrollArea_scrollTrack--26rEt.ScrollArea_isDropdown--G0S4K {
    bottom: 32px; }

.ScrollArea_scrollThumb--LJXtI {
  border-radius: 2px;
  background-color: rgba(233, 227, 190, 0.5); }
