.ButtonYellowOutlineLarge_base--pahyT {
  height: 50px;
  padding: 0 50px;
  border: 1px solid #f9b723;
  border-radius: 25px;
  background-color: transparent;
  color: #f9b723;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s; }
  .ButtonYellowOutlineLarge_base--pahyT:focus {
    outline: none; }
  .ButtonYellowOutlineLarge_base--pahyT:hover {
    background-color: #f9b723;
    color: #000; }
  .ButtonYellowOutlineLarge_base--pahyT.ButtonYellowOutlineLarge_isWide--2ISyD {
    width: 100%; }
  .ButtonYellowOutlineLarge_base--pahyT mark {
    background: transparent;
    color: #805c0a; }
