.ErrorBlocked_base--26q_4 {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center; }

.ErrorBlocked_icon--2XFI4 {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 0 0 50px;
  background: url(images/error-blocked.png) center no-repeat;
  vertical-align: top; }
