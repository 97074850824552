.Table_base--SDe3o {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #3f3c3b; }
  .Table_base--SDe3o::before, .Table_base--SDe3o::after {
    position: absolute;
    right: -1px;
    left: 1px;
    background-repeat: repeat-x;
    background-position: center;
    content: ''; }
  .Table_base--SDe3o::before {
    top: 47px;
    height: 5px;
    background-image: url(images/table-shadow-head.png);
    opacity: .6; }
  .Table_base--SDe3o::after {
    bottom: -4px;
    height: 3px;
    background-image: url(images/table-shadow-body.png); }
  .Table_base--SDe3o.Table_isProcessing--2x1Hr {
    opacity: .3; }
  .Table_base--SDe3o.Table_isOwn--Y_KFc tbody > tr {
    cursor: pointer; }
  .Table_base--SDe3o.Table_isEmpty--31Ui- td {
    height: 500px;
    color: #878686;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    text-shadow: none; }
  .Table_base--SDe3o thead {
    border-bottom: 1px solid #3f3c3b;
    background: #0d0d0d; }
  .Table_base--SDe3o th {
    height: 45px;
    padding: 0 0 0 35px;
    line-height: 14px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap; }
  .Table_base--SDe3o tbody > tr {
    background: #444545; }
    .Table_base--SDe3o tbody > tr:nth-child(2n) {
      background: #525252; }
  .Table_base--SDe3o td {
    height: 50px;
    padding: 0 0 0 35px;
    color: #fffae9;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    text-shadow: 0 2px 0.65px rgba(13, 14, 15, 0.4);
    vertical-align: middle;
    white-space: nowrap; }
