.ModState_base--35GsD {
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap; }
  .ModState_base--35GsD.ModState_isBlock--1zC1w {
    display: inline-block;
    height: 32px;
    padding: 0 20px;
    border-radius: 10px;
    color: #050505;
    font-size: 11px;
    font-weight: 700;
    line-height: 32px;
    vertical-align: top; }
  .ModState_base__published--SiVMm {
    color: #5eb940; }
    .ModState_base__published--SiVMm.ModState_isBlock--1zC1w {
      background-color: #5eb940; }
  .ModState_base__draft--2rQ2a {
    color: #959595; }
    .ModState_base__draft--2rQ2a.ModState_isBlock--1zC1w {
      background-color: #959595; }
  .ModState_base__rejected--2khS3 {
    color: #db2d1b; }
    .ModState_base__rejected--2khS3.ModState_isBlock--1zC1w {
      background-color: #db2d1b; }
  .ModState_base__review--x53zR {
    color: #f9b723; }
    .ModState_base__review--x53zR.ModState_isBlock--1zC1w {
      background-color: #f9b723; }
  .ModState_base__hidden--H8P3e {
    color: #fff; }
    .ModState_base__hidden--H8P3e.ModState_isBlock--1zC1w {
      background-color: #fff; }
  .ModState_base__published_update_in_review--3EWzm {
    color: #f9b723; }
    .ModState_base__published_update_in_review--3EWzm.ModState_isBlock--1zC1w {
      background-color: #f9b723; }
  .ModState_base__published_update_rejected--3w8at {
    color: #db2d1b; }
    .ModState_base__published_update_rejected--3w8at.ModState_isBlock--1zC1w {
      background-color: #db2d1b; }
