.Textarea_base--3AkKX {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: rgba(5, 5, 5, 0.4);
  color: #e9e3be;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
  resize: none;
  transition: background-color 0.4s, border-color 0.4s; }
  .Textarea_base--3AkKX:hover:not(.Textarea_isDisabled--1Kydt), .Textarea_base--3AkKX:focus:not(.Textarea_isDisabled--1Kydt) {
    outline: none;
    background-color: #050505; }
  .Textarea_base--3AkKX.Textarea_isDisabled--1Kydt {
    opacity: .3; }
  .Textarea_base--3AkKX.Textarea_isError--3YLPt {
    border-color: #db2d1b;
    background-color: #050505; }
