.ScrollToTop_base--N9zqs {
  position: fixed;
  right: 40px;
  bottom: 116px;
  z-index: 994;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e4deb9;
  background-image: url(images/scroll-to-top.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  cursor: pointer;
  transition: background-color 0.4s, opacity 0.4s; }
  .ScrollToTop_base--N9zqs:hover {
    background-color: #f9f5e1; }
  .ScrollToTop_base--N9zqs.ScrollToTop_isVisible--2ZpLZ {
    opacity: 1; }
