.AddMod_base--1eBd8 {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 302px;
  height: 340px;
  border-radius: 10px;
  cursor: pointer; }
  .AddMod_base--1eBd8::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 338px;
    height: 376px;
    background: url(images/add-mod-background.png) center no-repeat;
    content: '';
    transition: opacity .33s;
    transform: translate(-50%, -50%); }
  .AddMod_base--1eBd8:hover::before {
    background: url(images/add-mod-background-hover.png) center no-repeat; }
  .AddMod_base--1eBd8.AddMod_isDisabled--sEAv_ {
    opacity: .3;
    pointer-events: none; }

.AddMod_body--1Ra0w {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: url(images/add-mod-plus.png) center no-repeat; }
  .AddMod_body--1Ra0w:hover {
    background: url(images/add-mod-plus-hover.png) center no-repeat; }
