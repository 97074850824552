.Tooltip_base--14aR8 {
  position: fixed;
  z-index: 997;
  box-sizing: border-box;
  max-width: 400px;
  padding: 12px 10px;
  visibility: hidden;
  border: 1px solid #0d0d0d;
  border-radius: 2px;
  background: #333434;
  color: #787877;
  font-size: 11px;
  line-height: 1;
  pointer-events: none; }
  .Tooltip_base--14aR8.Tooltip_isVisible--1_yXP {
    visibility: visible; }
  .Tooltip_base--14aR8.Tooltip_isWide--3laMn {
    max-width: none; }
