.DialogConfirmation_base--3ugHI {
  width: 565px; }

.DialogConfirmation_content--2UPWj {
  margin: 0 0 25px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }

.DialogConfirmation_checkbox--2Eou2 {
  margin: 0 0 40px; }

.DialogConfirmation_button--4Irbt {
  display: inline-block;
  vertical-align: top; }
  .DialogConfirmation_button--4Irbt:not(:last-child) {
    margin: 0 20px 0 0; }
