@media all and (max-width: 1365px) {
  .EditComments_base--3ztoi {
    margin: 60px 0 0;
    padding: 34px 0 0;
    border-top: 1px solid #2b2b2c; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .EditComments_base--3ztoi {
    position: absolute;
    top: 0;
    right: 0;
    width: 302px; } }

@media all and (min-width: 1408px) {
  .EditComments_base--3ztoi {
    position: absolute;
    top: 0;
    right: 0;
    width: 302px; } }

.EditComments_base--3ztoi.EditComments_isDisabled--1Mf5G {
  opacity: .3;
  pointer-events: none; }

.EditComments_title--PfGBE {
  margin: 0 0 28px; }

@media all and (max-width: 1365px) {
  .EditComments_comments--262wQ {
    margin: 35px 0 0; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .EditComments_comments--262wQ {
    margin: 55px 0 0; } }

@media all and (min-width: 1408px) {
  .EditComments_comments--262wQ {
    margin: 55px 0 0; } }

.EditComments_button--2haYe {
  margin: 30px 0 0;
  text-align: center; }

.EditComments_paginator--3TRT0 {
  margin: 30px 0 0; }

.EditComments_paginatorInner--2Njy9 {
  position: relative;
  left: 50%;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  transform: translateX(-50%); }
