.EditUploadUpdateAdd_row--3YK8l {
  display: flex;
  flex-flow: row nowrap; }
  .EditUploadUpdateAdd_row--3YK8l:not(:last-child) {
    margin: 0 0 20px; }

.EditUploadUpdateAdd_label--115b3 {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditUploadUpdateAdd_field--3cs_D {
  flex: 1 1 100%; }
