.News_base--V3jyz {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  height: 100%; }

.News_leftColumn--e3fHe {
  position: relative;
  box-sizing: border-box;
  height: 100%; }
  @media all and (max-width: 1365px) {
    .News_leftColumn--e3fHe {
      flex: 0 0 384px;
      padding: 0 39px 0 0; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .News_leftColumn--e3fHe {
      flex: 0 0 642px;
      padding: 0 39px 0 0; } }
  @media all and (min-width: 1408px) {
    .News_leftColumn--e3fHe {
      flex: 0 0 684px;
      padding: 0 39px 0 0; } }

.News_rightColumn--3rSFx {
  position: relative;
  box-sizing: border-box;
  height: 100%; }
  @media all and (max-width: 1365px) {
    .News_rightColumn--3rSFx {
      flex: 0 0 584px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .News_rightColumn--3rSFx {
      flex: 0 0 644px; } }
  @media all and (min-width: 1408px) {
    .News_rightColumn--3rSFx {
      flex: 0 0 644px; } }

.News_head--1mjA3 {
  margin: 0 0 32px; }
