.ContentPage_base--FGEOQ {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; }

.ContentPage_head--1vQez {
  flex: 0 0 auto; }

.ContentPage_body--2qyKA {
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.ContentPage_inner--_rueZ {
  padding: 43px 0 0; }
  @media all and (min-width: 1408px) {
    .ContentPage_inner--_rueZ {
      width: 929px; } }
