.Search_base--3uKnr {
  position: relative;
  width: 302px;
  height: 32px;
  color: #858271;
  line-height: 32px; }

.Search_input--yfjjU {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 38px 0 20px;
  border: none;
  border-radius: 16px;
  background: none;
  background-color: rgba(5, 5, 5, 0.4);
  color: inherit;
  font-family: inherit;
  font-size: 14px;
  line-height: inherit;
  transition: background-color 0.4s; }
  .Search_input--yfjjU:hover {
    background-color: #050505; }
  .Search_base--3uKnr.Search_isActive--2MUEf .Search_input--yfjjU, .Search_input--yfjjU:focus {
    outline: none;
    box-shadow: 0 0 20px 0 rgba(255, 84, 0, 0.2);
    background-color: #f9b723;
    color: #050505; }

.Search_button--1PZY0 {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 3;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transform: translateY(-50%); }
  .Search_button--1PZY0::before, .Search_button--1PZY0::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .Search_button--1PZY0::before {
    background-image: url(images/search-button.svg);
    opacity: 1; }
  .Search_button--1PZY0::after {
    background-image: url(images/search-button-hover.svg);
    opacity: 0; }
  .Search_base--3uKnr.Search_isActive--2MUEf .Search_button--1PZY0::before,
  .Search_input--yfjjU:focus + .Search_button--1PZY0::before {
    opacity: 0; }
  .Search_base--3uKnr.Search_isActive--2MUEf .Search_button--1PZY0::after,
  .Search_input--yfjjU:focus + .Search_button--1PZY0::after {
    opacity: 1; }

.Search_placeholder--2HZnW {
  position: absolute;
  top: 0;
  right: 34px;
  bottom: 0;
  left: 20px;
  z-index: 2;
  display: none;
  padding: 1px;
  color: #858271;
  font-size: 11px;
  line-height: inherit;
  text-transform: uppercase;
  pointer-events: none; }
  .Search_placeholder--2HZnW.Search_isVisible--1_pIN {
    display: block; }
