.Landing_base--2dwxK {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; }

.Landing_head--fQx9i {
  position: relative;
  z-index: 1;
  flex: 0 0 auto; }

.Landing_body--S-zCn {
  position: relative;
  z-index: 2;
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }
  @media all and (max-width: 1365px) {
    .Landing_body--S-zCn {
      margin-top: -211px; } }
  @media all and (min-width: 1408px) {
    .Landing_body--S-zCn {
      margin-top: -265px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Landing_body--S-zCn {
      margin-top: -265px; } }

.Landing_selectPanel--1iAKM {
  position: relative;
  z-index: 2;
  margin: 0 0 20px;
  padding: 0 30px; }

.Landing_categories--1o1oF {
  position: relative;
  z-index: 1; }
