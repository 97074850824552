.Dialog_base--mtWpH {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden; }
  .Dialog_base--mtWpH.Dialog_isOpened--2Y8lL {
    visibility: visible; }

.Dialog_overlay--2Wbv8 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0; }
  .Dialog_base--mtWpH.Dialog_isOpened--2Y8lL .Dialog_overlay--2Wbv8 {
    opacity: 1; }

.Dialog_dialog--172x4 {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 35px 40px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 50px 0 rgba(0, 0, 0, 0.75);
  background: #1c1c1e; }

.Dialog_close--2FBx1 {
  position: absolute;
  top: 7px;
  right: 11px;
  width: 30px;
  height: 30px;
  background: url(images/dialog-close.png) center no-repeat;
  opacity: .5;
  cursor: pointer;
  transition: opacity 0.4s; }
  .Dialog_close--2FBx1:hover {
    opacity: 1; }
