.Alert_base--CHOJP {
  position: relative;
  display: inline;
  padding: 0 0 0 20px;
  color: #f9b723;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 15px; }
  .Alert_base--CHOJP::before {
    position: absolute;
    top: -7px;
    left: -9px;
    width: 30px;
    height: 30px;
    background: url(images/alert.png) center no-repeat;
    content: ''; }
