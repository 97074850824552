.EditModVersion_base--19Kpk {
  display: flex;
  flex-flow: row nowrap; }

.EditModVersion_label--1tn11 {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditModVersion_field--2GI_s {
  flex: 1 1 100%; }
