.Screenshots_base--1WaXt {
  margin: 0 0 58px;
  border-radius: 10px;
  background-color: #050505; }

.Screenshots_image--1SjOp {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  vertical-align: top; }

.Screenshots_view--3xPB7 {
  display: inline-block;
  border-radius: 10px;
  vertical-align: top;
  cursor: pointer; }
  .Screenshots_view--3xPB7 img {
    display: inline-block;
    border-radius: 10px;
    vertical-align: top; }

.Screenshots_previews--2SSlh {
  position: relative;
  z-index: 1;
  padding: 20px; }
  .Screenshots_previews--2SSlh::after {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    height: 59px;
    background: url(images/screenshots-shadow.png) center repeat-x;
    content: ''; }

.Screenshots_preview--2Bmnw {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  vertical-align: top;
  cursor: pointer; }
  .Screenshots_preview--2Bmnw:not(:last-child) {
    margin: 0 22px 0 0; }
  .Screenshots_preview--2Bmnw::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    content: ''; }
  .Screenshots_preview--2Bmnw.Screenshots_isActive--3MW2C::after {
    box-shadow: inset 0 0 0 2px #f9b723, 0 0 20px 0 rgba(255, 84, 0, 0.3); }
  .Screenshots_preview--2Bmnw img {
    display: inline-block;
    border-radius: 10px;
    vertical-align: top; }

.Screenshots_counter--2BuPE {
  position: absolute;
  top: -20px;
  left: 20px;
  z-index: 2;
  color: #a29e87;
  font-size: 14px;
  line-height: 25px; }
  .Screenshots_counter--2BuPE > span {
    color: #ebe8c8;
    font-weight: 700; }

.Screenshots_lightboxCounter--2kzNc {
  padding: 40px 0;
  color: #726f5f;
  font-size: 18px;
  text-align: center;
  white-space: nowrap; }
  .Screenshots_lightboxCounter--2kzNc > span {
    color: #ebe8c8;
    font-weight: 700; }
