.Details_base--3Z6C1 {
  position: relative; }

.Details_reactBlur--3d0rU {
  height: 294px;
  overflow: hidden; }

.Details_cover--1fLvP {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 294px; }
  .Details_cover--1fLvP::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(28, 28, 30, 0.6);
    content: ''; }

.Details_voting--2XXuE {
  position: absolute;
  right: 0; }
  @media all and (max-width: 1365px) {
    .Details_voting--2XXuE {
      top: 20px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Details_voting--2XXuE {
      top: -10px; } }
  @media all and (min-width: 1408px) {
    .Details_voting--2XXuE {
      top: -10px; } }

.Details_header--39s6E {
  position: relative; }
  @media all and (max-width: 1365px) {
    .Details_header--39s6E {
      width: 635px;
      height: 249px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Details_header--39s6E {
      width: 929px;
      height: 219px; } }
  @media all and (min-width: 1408px) {
    .Details_header--39s6E {
      width: 929px;
      height: 219px; } }

.Details_clampLines--2ZO-m {
  word-wrap: break-word; }

.Details_details--26vhR {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0; }

@media all and (max-width: 1365px) {
  .Details_summary--3QTvk {
    margin: 0 0 24px; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .Details_summary--3QTvk {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 29px; } }

@media all and (min-width: 1408px) {
  .Details_summary--3QTvk {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 29px; } }

.Details_tags--WHgkz {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }
  @media all and (max-width: 1365px) {
    .Details_tags--WHgkz {
      margin: 0 0 14px; } }

.Details_tag--2SUsH {
  display: inline-block;
  margin: 0 30px 0 0;
  vertical-align: top;
  white-space: nowrap; }
  .Details_tag--2SUsH:not(:last-child) {
    margin-right: 8px; }

.Details_author--1Ji9z {
  display: inline-block;
  max-width: 200px;
  margin: 0 20px 0 0;
  overflow: hidden;
  color: #b8b8a2;
  font-size: 15px;
  line-height: 21px;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap; }

.Details_owner--1YtPC {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  color: #fab81b;
  font-size: 15px;
  line-height: 21px;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer; }

@media all and (max-width: 1365px) {
  .Details_body--3pb0h {
    width: 100%; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .Details_body--3pb0h {
    width: 929px; } }

@media all and (min-width: 1408px) {
  .Details_body--3pb0h {
    width: 929px; } }

.Details_actions--oscWT {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 40px;
  padding: 20px 0;
  border-bottom: 1px solid #2b2b2c; }

.Details_action--3CC1G:not(:last-child) {
  margin-right: 30px; }

.Details_actions__disabled--_VTpx .Details_action--3CC1G {
  margin-right: 10px; }

.Details_actionsMessage--3X95H {
  color: #e9e3be;
  font-size: 16px;
  line-height: 1; }

.Details_title--3yLqB {
  position: relative;
  margin: 0 0 34px; }

.Details_changelogs--2GsgD {
  word-wrap: break-word; }

.Details_descriptionLanguageSelect--1lHCi {
  position: absolute;
  top: -2px;
  right: 0; }

.Details_loadMore--3xVkB {
  margin: 0 0 50px;
  text-align: center; }

.Details_commentsTitle--3V55o {
  position: relative;
  z-index: 2;
  height: 18px; }

.Details_commentsTitleText--3msL8 {
  color: #e9e3be;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase; }

.Details_commentsTitleLanguageSelect--2Tbgj {
  position: absolute;
  top: -2px;
  right: 0;
  z-index: 2; }

.Details_commentsMessage--2o_Mv {
  position: relative;
  z-index: 2;
  padding: 37px 0 38px;
  border-bottom: 1px solid #2b2b2c;
  color: #e9e3be;
  font-size: 20px; }

.Details_commentsInfo--27Wg2 {
  position: relative;
  z-index: 2;
  margin: 22px 0 34px;
  color: #b8b8a2;
  font-size: 14px;
  line-height: 20px; }

.Details_comments--1hbhJ {
  min-height: 200px; }
  .Details_comments__en--3l9wC .hc__menu__item.hc__menu__comment.hc_unclick .hc__menu__count {
    left: 100px; }
  .Details_comments__ru--puDKB .hc__menu__item.hc__menu__comment.hc_unclick .hc__menu__count {
    left: 130px; }
