.Nav_base--1n5qL {
  height: 66px;
  background-color: rgba(39, 39, 41, 0.95); }

.Nav_inner--2Qfqa {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto; }
  @media all and (max-width: 1365px) {
    .Nav_inner--2Qfqa {
      width: 1022px;
      padding: 0 28px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Nav_inner--2Qfqa {
      width: 1366px;
      padding: 0 40px; } }
  @media all and (min-width: 1408px) {
    .Nav_inner--2Qfqa {
      width: 1408px;
      padding: 0 40px; } }

.Nav_logo--1ME-i {
  display: inline-block;
  width: 16px;
  height: 21px;
  margin: 0 45px 0 0;
  background: url(images/nav-logo.png) center no-repeat;
  vertical-align: top; }

.Nav_link--2i_9y {
  position: relative;
  color: #e0ddce;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s; }
  .Nav_link--2i_9y:not(:last-child) {
    margin: 0 40px 0 0; }
  .Nav_link--2i_9y::after {
    position: absolute;
    right: 0;
    bottom: -26px;
    left: 0;
    height: 2px;
    opacity: 0;
    content: '';
    transition: opacity 0.4s, box-shadow 0.4s; }
  .Nav_link--2i_9y:hover {
    color: #f9f5e1; }
    .Nav_link--2i_9y:hover::after {
      background-color: #e4deb9;
      opacity: 1; }
  .Nav_link--2i_9y.Nav_isActive--CrxAe::after {
    box-shadow: 0 0 20px 1px rgba(255, 84, 0, 0.8);
    background-color: #f9b723;
    opacity: 1; }

.Nav_counter--bUxya {
  position: absolute;
  top: -3px;
  right: -9px;
  height: 12px;
  padding: 0 3px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 #ff5400;
  background: #f9b723;
  color: #050505;
  font-size: 11px; }

.Nav_search--QaXrt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  @media all and (max-width: 1365px) {
    .Nav_search--QaXrt {
      right: 28px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Nav_search--QaXrt {
      right: 40px; } }
  @media all and (min-width: 1408px) {
    .Nav_search--QaXrt {
      right: 40px; } }
