.PreviewEdit_base--2QXYq {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #050505;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer; }
  .PreviewEdit_base--2QXYq::before, .PreviewEdit_base--2QXYq::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .PreviewEdit_base--2QXYq::before {
    background-image: url(images/preview-edit.png);
    opacity: 1; }
  .PreviewEdit_base--2QXYq::after {
    background-image: url(images/preview-edit-hover.png);
    opacity: 0; }
  .PreviewEdit_base--2QXYq:hover::before {
    opacity: 0; }
  .PreviewEdit_base--2QXYq:hover::after {
    opacity: 1; }
