.EditChangelogForm_row--2S-Z1 {
  display: flex;
  flex-flow: row nowrap; }
  .EditChangelogForm_row--2S-Z1:not(:last-child) {
    margin: 0 0 20px; }

.EditChangelogForm_label--R9Trw {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditChangelogForm_field--2bZqX {
  flex: 1 1 100%; }

.EditChangelogForm_divider--22LtX {
  margin: 20px 0 20px 342px; }

.EditChangelogForm_cancel--1QNkU {
  margin: -5px 0 15px;
  text-align: right; }
