.NotificationBlockedUser_inner--3U8VM {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center; }

.NotificationBlockedUser_icon--2Kr7X {
  flex: 0 0 61px;
  width: 61px;
  height: 61px;
  background: url(images/notification-blocked-user-icon.png) center no-repeat; }

.NotificationBlockedUser_content--2RFLV {
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 0 20px; }

.NotificationBlockedUser_button--1llVv {
  flex: 0 0 auto;
  padding: 0 10px; }
