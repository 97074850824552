.EditComment_base--DfdtJ {
  display: flex;
  flex-flow: row nowrap; }

.EditComment_label--1wsKh {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditComment_field--2CVFa {
  flex: 1 1 100%; }
