@media all and (max-width: 1365px) {
  .EditNotifications_base--1OJQI {
    margin: 40px 0 0; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .EditNotifications_base--1OJQI {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 302px; } }

@media all and (min-width: 1408px) {
  .EditNotifications_base--1OJQI {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 302px; } }
