.DialogReportModComplete_base--3bvYq {
  width: 565px;
  padding: 0 0 10px; }

.DialogReportModComplete_content--19JCe {
  margin: 0 0 37px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px; }
  .DialogReportModComplete_content--19JCe mark {
    color: #f9f5e1;
    font-size: 700; }
