.NewsHeadersList_base--2yRcT {
  position: absolute;
  top: 155px;
  right: 39px;
  bottom: -50px;
  left: 0;
  margin: 0 -8px 0 0;
  padding: 0 8px 0 0;
  overflow: hidden; }

.NewsHeadersList_wrapper--1K98Z {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll; }

.NewsHeadersList_items--r3aaG {
  padding: 0 0 50px; }

.NewsHeadersList_item--MrbBt {
  position: relative;
  box-sizing: border-box;
  min-height: 84px;
  padding: 16px 30px 25px;
  border-top: 1px #2b2b2c solid;
  border-left: 2px transparent solid;
  cursor: pointer; }
  .NewsHeadersList_item--MrbBt.NewsHeadersList_isActive--23x36 {
    z-index: 2;
    margin: 0 -8px 0 0;
    border-top-color: transparent;
    border-left-color: #f9b723;
    border-radius: 0 10px 10px 0;
    background: rgba(5, 5, 5, 0.5);
    cursor: default; }
    .NewsHeadersList_item--MrbBt.NewsHeadersList_isActive--23x36 + .NewsHeadersList_divider--2u4rF {
      display: none; }
  .NewsHeadersList_isActive--23x36 + .NewsHeadersList_item--MrbBt {
    border-color: transparent; }

.NewsHeadersList_title--2jmc_ {
  color: #e9e3be;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  transition: color 0.4s; }
  .NewsHeadersList_item--MrbBt:hover .NewsHeadersList_title--2jmc_,
  .NewsHeadersList_isActive--23x36 .NewsHeadersList_title--2jmc_ {
    color: #f9b723; }

.NewsHeadersList_date--2s-lk {
  margin: 9px 0 0;
  color: #a29e87;
  font-size: 11px; }

.NewsHeadersList_divider--2u4rF {
  position: relative;
  margin: -51px 0 0; }

.NewsHeadersList_paginator--2KBqB {
  position: relative;
  margin: 30px 0 0;
  padding: 0 0 50px;
  text-align: center; }
