.EditAuthorName_base--1Qe9r {
  display: flex;
  flex-flow: row nowrap; }

.EditAuthorName_label--Pj1c4 {
  flex: 0 0 342px;
  box-sizing: border-box;
  margin: 4px 0 0;
  padding: 0 40px 0 0; }

/* stylelint-disable */
_:-ms-lang(x), .EditAuthorName_label--Pj1c4 {
  flex: 0 0 302px; }

/* stylelint-enable */
.EditAuthorName_field--iD92T {
  flex: 1 1 100%; }
