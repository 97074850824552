.Input_base--1yUwI {
  position: relative;
  box-sizing: border-box;
  height: 44px; }

.Input_input--w3bHE {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: rgba(5, 5, 5, 0.4);
  color: #e9e3be;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: background-color 0.4s, border-color 0.4s; }
  .Input_input--w3bHE:hover, .Input_input--w3bHE:focus {
    outline: none;
    background-color: #050505; }
  .Input_base--1yUwI.Input_isError--owCyp .Input_input--w3bHE {
    border-color: #db2d1b;
    background-color: #050505; }

.Input_placeholder--1waif {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 20px;
  z-index: 2;
  display: none;
  padding: 1px;
  color: #858271;
  font-size: 14px;
  line-height: 44px;
  pointer-events: none; }
  .Input_placeholder--1waif.Input_isVisible--2vwbJ {
    display: block; }
