.NewsContentsList_base--1K1sz {
  position: absolute;
  top: -96px;
  bottom: -50px;
  width: 100%;
  overflow: hidden; }

.NewsContentsList_wrapper--2OpEm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll; }

.NewsContentsList_items--397m- {
  padding: 0 0 50px; }

.NewsContentsList_item--2Xfrj {
  position: relative;
  box-sizing: border-box;
  margin: 20px 0 0;
  padding: 37px 40px 35px;
  border-radius: 10px;
  background: rgba(5, 5, 5, 0.5); }
  @media all and (max-width: 1365px) {
    .NewsContentsList_item--2Xfrj:first-child {
      margin: 131px 0 0; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .NewsContentsList_item--2Xfrj:first-child {
      margin: 161px 0 0; } }
  @media all and (min-width: 1408px) {
    .NewsContentsList_item--2Xfrj:first-child {
      margin: 161px 0 0; } }

.NewsContentsList_title--fXXNF {
  color: #e9e3be;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

.NewsContentsList_date--3RvXc {
  margin: 10px 0 0;
  color: #a29e87;
  font-size: 11px; }

.NewsContentsList_content--1seRo {
  width: 100%;
  margin: 30px 0 0;
  overflow: hidden;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 26px; }
  .NewsContentsList_content--1seRo img {
    max-width: 100% !important;
    height: auto !important; }
  .NewsContentsList_content--1seRo p {
    margin: 30px 0; }
    .NewsContentsList_content--1seRo p:first-child {
      margin: 0 0 30px; }
