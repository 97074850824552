.Categories_base--2ufeh {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 11px 30px;
  border-radius: 25px;
  background-color: #050505; }

.Categories_caption--2hqd6 {
  display: inline-block;
  margin: 0 7px 0 0;
  color: #e9e3be;
  font-size: 14px;
  font-weight: 700;
  line-height: 29px;
  text-transform: uppercase;
  vertical-align: top; }

.Categories_tag--pxJqV {
  display: inline-block;
  margin: 5px 0;
  vertical-align: top; }
  .Categories_tag--pxJqV:not(:last-child) {
    margin-right: 10px; }
