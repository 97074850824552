.EditLanguageAdd_base--1u9wt {
  display: flex;
  flex-flow: row nowrap;
  margin: 40px 0; }

.EditLanguageAdd_label--Ignzn {
  flex: 0 0 342px; }

.EditLanguageAdd_field--24U4u {
  flex: 1 1 100%; }

.EditLanguageAdd_select--15qHO {
  width: 275px; }
