.EditGameVersion_base--3BdIb {
  display: flex;
  flex-flow: row nowrap; }

.EditGameVersion_label--tunQ6 {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditGameVersion_field--1I8x9 {
  flex: 1 1 100%; }

.EditGameVersion_select--2Bdj2 {
  width: 275px; }
