.EditUploadUpdateList_row--eW88T {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start; }

.EditUploadUpdateList_label--1aIjh {
  flex: 0 0 342px; }

.EditUploadUpdateList_field--21Oka {
  flex: 1 1 100%; }

.EditUploadUpdateList_tr--N01_d {
  padding: 15px 0; }
  .EditUploadUpdateList_tr--N01_d:first-child {
    padding-top: 0; }
  .EditUploadUpdateList_tr--N01_d:last-child {
    padding-bottom: 0; }
  .EditUploadUpdateList_tr--N01_d:not(:last-child) {
    border-bottom: 1px solid #2b2b2c; }

.EditUploadUpdateList_inner--2p07B {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline; }

.EditUploadUpdateList_td--3hY-a {
  box-sizing: border-box;
  line-height: 16px; }
  .EditUploadUpdateList_td__mod-version--2WKpT {
    flex: 0 0 190px; }
  .EditUploadUpdateList_td__game-version--1WjHh {
    flex: 0 0 140px;
    padding-right: 10px;
    color: #e9e3be;
    font-size: 14px; }
  .EditUploadUpdateList_td__state--3jrga {
    flex: 1 1 100%;
    padding-right: 10px;
    color: #e9e3be;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap; }
  .EditUploadUpdateList_td__remove--3hDJO {
    flex: 0 0 21px; }

.EditUploadUpdateList_notification--2V_tY {
  margin: 15px 0 0; }
