.Tabs_base--xmcv8 {
  position: relative;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }
  .Tabs_base--xmcv8.Tabs_isWide--2oEmQ {
    box-sizing: border-box;
    padding-right: 61px; }

.Tabs_container--3DEn9 {
  overflow: hidden; }

.Tabs_inner--8UFq_ {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #7c7c7c;
  vertical-align: top;
  transition: left 0.4s; }

.Tabs_item--3Mb0D {
  display: inline-block;
  height: 29px;
  margin: 0 0 -1px;
  padding: 0 17px;
  border: 1px solid #7c7c7c;
  border-radius: 2px 2px 0 0;
  background: #1d1d1c;
  color: #7c7c7c;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 31px;
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer; }
  .Tabs_item--3Mb0D:not(:last-child) {
    margin-right: 7px; }
  .Tabs_item--3Mb0D:hover, .Tabs_item--3Mb0D.Tabs_isActive--fThiy {
    background: #7c7c7c;
    color: #2a2a2a; }
  .Tabs_item--3Mb0D.Tabs_isActive--fThiy {
    cursor: default; }

.Tabs_buttons--xgW-Q {
  position: absolute;
  right: 0;
  bottom: 0; }
  .Tabs_buttons--xgW-Q::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    width: 5px;
    background: url(images/tabs-buttons-shadow.png) center no-repeat;
    content: ''; }

.Tabs_button--123vE {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 29px;
  border: 1px solid #7c7c7c;
  border-radius: 2px 2px 0 0;
  background: #1d1d1c;
  vertical-align: top;
  cursor: pointer; }
  .Tabs_button--123vE:not(:last-child) {
    margin-right: 3px; }
  .Tabs_button--123vE::before {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 7px;
    height: 13px;
    background-image: url(images/tabs-buttons-arrow.png);
    background-repeat: no-repeat;
    background-position-y: 0;
    content: ''; }
  .Tabs_button__prev--d-BWP::before {
    background-position-x: 0; }
  .Tabs_button__next--3gj1E::before {
    background-position-x: -7px; }
  .Tabs_button--123vE.Tabs_isDisabled--2SWXm {
    opacity: .5;
    cursor: default; }
  .Tabs_button--123vE:not(.Tabs_isDisabled--2SWXm):hover {
    background: #7c7c7c; }
    .Tabs_button--123vE:not(.Tabs_isDisabled--2SWXm):hover::before {
      background-position-y: -13px; }
