.ButtonRedLarge_base--cQdMp {
  position: relative;
  height: 50px;
  padding: 0 40px;
  border: none;
  border-radius: 25px;
  background-color: #db2d1b;
  color: #050505;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s; }
  .ButtonRedLarge_base__speakers--tlzMI {
    position: relative;
    padding-left: 70px; }
    .ButtonRedLarge_base__speakers--tlzMI::before {
      position: absolute;
      top: 50%;
      left: 40px;
      width: 20px;
      height: 15px;
      margin: -8px 0 0 0;
      background: url(images/button-red-large-speaker.svg) center no-repeat;
      content: ''; }
  .ButtonRedLarge_base--cQdMp:focus {
    outline: none; }
  .ButtonRedLarge_base--cQdMp:hover {
    background-color: #ff482a; }
