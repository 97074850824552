.Spinner_base--OMJwW {
  position: relative;
  width: 114px;
  height: 114px;
  text-align: center; }

.Spinner_spinner--36SnL {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(images/spinner.png) center no-repeat;
  animation: Spinner_spin--1tuBf 3s linear infinite; }

@keyframes Spinner_spin--1tuBf {
  to {
    transform: rotate(360deg); } }

.Spinner_logo--IeIDq {
  position: absolute;
  top: 6px;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(images/spinner-logo.png) center no-repeat; }
