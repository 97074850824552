.LanguageSelect_base--3otLE {
  position: relative;
  width: 127px;
  height: 21px; }

.LanguageSelect_value--38cTZ {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 2px 27px 0;
  border-radius: 10px;
  background-color: #272729;
  color: #e9e3be;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.4s; }
  .LanguageSelect_value--38cTZ::before {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3);
    background-image: url(images/flags.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: ''; }
  .LanguageSelect_value--38cTZ::after {
    position: absolute;
    top: 50%;
    right: 11px;
    width: 9px;
    height: 6px;
    background-image: url(images/language-select-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 -6px;
    content: '';
    transform: translateY(-50%); }
  .LanguageSelect_value--38cTZ.LanguageSelect_isOpened--1BzSm {
    border-radius: 0 0 10px 10px; }
    .LanguageSelect_value--38cTZ.LanguageSelect_isOpened--1BzSm::after {
      background-position: 0 0; }
  .LanguageSelect_value__ru--1xw-X::before {
    background-position: 0 0; }
  .LanguageSelect_value__en--3PCqq::before {
    background-position: 0 -15px; }
  .LanguageSelect_value--38cTZ:hover:not(.LanguageSelect_isOpened--1BzSm) {
    background-color: #3a3a3c; }

.LanguageSelect_dropdown--KxGdT {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  display: none;
  border-radius: 10px 10px 0 0;
  background-color: #272729; }
  .LanguageSelect_base--3otLE.LanguageSelect_isOpened--1BzSm .LanguageSelect_dropdown--KxGdT {
    display: block; }

.LanguageSelect_option--3npgp {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 21px;
  padding: 2px 27px 0;
  border-radius: 10px;
  color: #e9e3be;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.4s; }
  .LanguageSelect_option--3npgp::before {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3);
    background-image: url(images/flags.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: ''; }
  .LanguageSelect_option__ru--1IsUB::before {
    background-position: 0 0; }
  .LanguageSelect_option__en--1oyCh::before {
    background-position: 0 -15px; }
  .LanguageSelect_option--3npgp:hover {
    background-color: #3a3a3c; }
