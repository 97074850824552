.ActionSubscribe_base--2jfcH {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionSubscribe_base--2jfcH::before, .ActionSubscribe_base--2jfcH::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #f9b723;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; }
  .ActionSubscribe_base--2jfcH::before {
    background-image: url(images/action-subscribe.svg);
    opacity: 1; }
  .ActionSubscribe_base--2jfcH::after {
    background-image: url(images/action-subscribe-hover.svg);
    opacity: 0; }
  .ActionSubscribe_base--2jfcH:hover:not(.ActionSubscribe_isDisabled--2Frrc) {
    color: #ffd428; }
    .ActionSubscribe_base--2jfcH:hover:not(.ActionSubscribe_isDisabled--2Frrc)::before {
      opacity: 0; }
    .ActionSubscribe_base--2jfcH:hover:not(.ActionSubscribe_isDisabled--2Frrc)::after {
      opacity: 1; }
    .ActionSubscribe_base--2jfcH:hover:not(.ActionSubscribe_isDisabled--2Frrc)::before, .ActionSubscribe_base--2jfcH:hover:not(.ActionSubscribe_isDisabled--2Frrc)::after {
      border-color: #f9b723;
      background-color: #f9b723; }
  .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc)::before {
    opacity: 0; }
  .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc)::after {
    opacity: 1; }
  .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc)::before, .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc)::after {
    border-color: #f9b723;
    background-color: #f9b723; }
  .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc):hover::before, .ActionSubscribe_base--2jfcH.ActionSubscribe_isSubscribed--1qSz3:not(.ActionSubscribe_isDisabled--2Frrc):hover::after {
    background-color: #ffd428; }
  .ActionSubscribe_base--2jfcH.ActionSubscribe_isDisabled--2Frrc {
    width: 50px;
    padding: 0;
    opacity: .3;
    cursor: default; }
