.ModName_base--35Bdi {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  color: #fffae9;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  text-overflow: ellipsis;
  text-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  word-spacing: -.05em; }

.ModName_title--qKkZ0::after {
  display: inline;
  color: #8b8b7b;
  content: ' / '; }

.ModName_title--qKkZ0:last-child::after {
  content: normal; }
