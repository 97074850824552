.FileUpload_base--1WCEZ {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase; }

.FileUpload_title--36maA {
  display: inline-block;
  margin: 0 20px 8px 0;
  color: #f9b723;
  vertical-align: top;
  cursor: pointer;
  transition: color 0.4s; }
  .FileUpload_title--36maA:hover {
    color: #ffd428; }

.FileUpload_caption--1qIRC {
  color: #858271; }
  .FileUpload_caption--1qIRC mark {
    background: transparent;
    color: #f9f5e1;
    font-weight: 700; }
  .FileUpload_caption--1qIRC em {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; }

.FileUpload_icon--1cuPz {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border: 1px solid #f9b723;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.4s; }
  .FileUpload_icon--1cuPz::before, .FileUpload_icon--1cuPz::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .FileUpload_icon--1cuPz::before {
    background-image: url(images/file-upload.svg);
    opacity: 1; }
  .FileUpload_icon--1cuPz::after {
    background-image: url(images/file-upload-hover.svg);
    opacity: 0; }
  .FileUpload_title--36maA:hover .FileUpload_icon--1cuPz {
    border-color: #f9b723;
    background-color: #f9b723; }
    .FileUpload_title--36maA:hover .FileUpload_icon--1cuPz::before {
      opacity: 0; }
    .FileUpload_title--36maA:hover .FileUpload_icon--1cuPz::after {
      opacity: 1; }
    .FileUpload_base--1WCEZ.FileUpload_isError--2zjLU .FileUpload_title--36maA:hover .FileUpload_icon--1cuPz {
      border-color: #f9b723; }
    .FileUpload_base--1WCEZ.FileUpload_isUploading--2H9ng .FileUpload_title--36maA:hover .FileUpload_icon--1cuPz {
      border-color: #29282a; }
  .FileUpload_base--1WCEZ.FileUpload_isError--2zjLU .FileUpload_icon--1cuPz {
    border-color: #db2d1b; }
  .FileUpload_base--1WCEZ.FileUpload_isUploading--2H9ng .FileUpload_icon--1cuPz {
    border: 3px solid #29282a;
    background: #131314 url(images/file-upload-cancel.png) center no-repeat; }
    .FileUpload_base--1WCEZ.FileUpload_isUploading--2H9ng .FileUpload_icon--1cuPz::before, .FileUpload_base--1WCEZ.FileUpload_isUploading--2H9ng .FileUpload_icon--1cuPz::after {
      content: normal; }
  .FileUpload_base--1WCEZ.FileUpload_isUploaded--1KbJN .FileUpload_icon--1cuPz::before {
    background-image: url(images/file-upload-uploaded.svg); }
  .FileUpload_base--1WCEZ.FileUpload_isUploaded--1KbJN .FileUpload_icon--1cuPz::after {
    background-image: url(images/file-upload-uploaded-hover.svg); }

.FileUpload_progressRing--1Gjo1 {
  position: absolute;
  top: -7px;
  left: -7px;
  width: 50px;
  height: 50px;
  opacity: 1;
  transition: opacity 0.4s;
  pointer-events: none; }
  .FileUpload_title--36maA:hover .FileUpload_progressRing--1Gjo1 {
    opacity: .3; }

.FileUpload_fileName--3o2SY {
  display: inline-block;
  max-width: 245px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap; }

.FileUpload_fileSize--TVaAK {
  margin: 0 0 0 7px; }

.FileUpload_input--1R-DH {
  display: none; }
