.ActionCancelMod_base--3Be-K {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionCancelMod_base--3Be-K::before, .ActionCancelMod_base--3Be-K::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #f9b723;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; }
  .ActionCancelMod_base--3Be-K::before {
    background-image: url(images/action-cancel-mod.png);
    opacity: 1; }
  .ActionCancelMod_base--3Be-K::after {
    background-image: url(images/action-cancel-mod-hover.png);
    opacity: 0; }
  .ActionCancelMod_base--3Be-K:hover:not(.ActionCancelMod_isDisabled--5i_xl) {
    color: #ffd428; }
    .ActionCancelMod_base--3Be-K:hover:not(.ActionCancelMod_isDisabled--5i_xl)::before {
      opacity: 0; }
    .ActionCancelMod_base--3Be-K:hover:not(.ActionCancelMod_isDisabled--5i_xl)::after {
      opacity: 1; }
    .ActionCancelMod_base--3Be-K:hover:not(.ActionCancelMod_isDisabled--5i_xl)::before, .ActionCancelMod_base--3Be-K:hover:not(.ActionCancelMod_isDisabled--5i_xl)::after {
      border-color: #f9b723;
      background-color: #f9b723; }
