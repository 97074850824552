.Caption_base--3tHHy {
  display: inline-block;
  margin: 0 9px 0 0;
  color: #e9e3be;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  vertical-align: top;
  white-space: nowrap;
  transition: color 0.4s; }
  .Caption_base--3tHHy.Caption_isLarge--187Po {
    font-size: 18px;
    line-height: 24px;
    white-space: normal; }
  .Caption_base--3tHHy.Caption_isRequired--2GfQU::after {
    margin: 0 0 0 4px;
    color: #db2d1b;
    font-size: 20px;
    content: '*'; }
  .Caption_base--3tHHy.Caption_isError--12tT6 {
    color: #db2d1b; }
