.ButtonGrayOutlineLarge_base--3HHhI {
  height: 50px;
  padding: 0 48px;
  border: 1px solid rgba(228, 222, 185, 0.2);
  border-radius: 25px;
  background-color: transparent;
  color: #e4deb9;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s; }
  .ButtonGrayOutlineLarge_base--3HHhI:focus {
    outline: none; }
  .ButtonGrayOutlineLarge_base--3HHhI:hover {
    background-color: #e4deb9;
    color: #000; }
