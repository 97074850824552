.EditMod_base--325Qj {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; }

.EditMod_head--JBnlH {
  flex: 0 0 auto; }

.EditMod_body--111ks {
  flex: 1 1 100%; }

.EditMod_inner--2bYSV {
  position: relative;
  height: 100%; }

.EditMod_content--1EZz1 {
  height: 100%; }
  @media all and (max-width: 1365px) {
    .EditMod_content--1EZz1 {
      width: 100%; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .EditMod_content--1EZz1 {
      width: 929px; } }
  @media all and (min-width: 1408px) {
    .EditMod_content--1EZz1 {
      width: 929px; } }

.EditMod_form--1f5fM {
  padding: 33px 0 0; }
  .EditMod_form--1f5fM.EditMod_isDisabled--1SqqC {
    opacity: .3;
    pointer-events: none; }

.EditMod_divider--KJfDZ {
  margin: 30px 0; }

.EditMod_attentions--1YY9W {
  margin: 37px 0 55px; }

.EditMod_attention--3T4Bh {
  margin: 20px 0;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px; }

.EditMod_buttons--1iSEa {
  position: relative; }

.EditMod_button--TqGoM {
  display: inline-block;
  vertical-align: top; }
  .EditMod_button--TqGoM:not(:last-child) {
    margin: 0 30px 0 0; }

.EditMod_actionRemoveMod--2pfuC {
  position: absolute;
  top: 0;
  right: 0; }

.EditMod_clampLines--2lD1c {
  word-wrap: break-word; }

.EditMod_notificationModerator--2UVWN {
  margin: 30px 0; }

.EditMod_notification--3oQKT:not(:last-child) {
  margin: 0 0 20px; }

.EditMod_info--2tlFT {
  padding: 17px 0;
  color: #b8b8a2;
  font-size: 16px;
  line-height: 36px; }

.EditMod_notificationContent--2SPlU {
  position: relative;
  font-size: 14px;
  line-height: 20px; }
  .EditMod_notificationContent--2SPlU mark {
    background: transparent;
    color: #db2d1b;
    font-size: 20px;
    line-height: 20px; }
  .EditMod_notificationContent--2SPlU::before {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: url(images/edit-notification-error.png) center no-repeat;
    vertical-align: top;
    content: ''; }
  .EditMod_notificationContent__info--1lpB0::before {
    background-color: #395eff; }
  .EditMod_notificationContent__warning--3TGyF::before {
    background-color: #db2d1b; }
