.EditChangelogList_row--1pw2Z {
  display: flex;
  flex-flow: row nowrap; }
  .EditChangelogList_row--1pw2Z:not(:last-child) {
    margin: 0 0 20px; }

.EditChangelogList_label--d2jU- {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditChangelogList_field--3CXmQ {
  flex: 1 1 100%; }

.EditChangelogList_divider--3mYJC {
  margin: 20px 0 20px 342px; }

.EditChangelogList_item--1BPFe {
  margin: 20px 0; }

.EditChangelogList_loadMore--cDEnE {
  margin: 20px 0 0 342px;
  text-align: center; }

.dragged {
  z-index: 993; }
