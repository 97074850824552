.Rating_base--2lT32 {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(255, 84, 0, 0.5);
  background-color: #170303;
  color: #fab81b;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0 0 20px rgba(255, 84, 0, 0.8);
  vertical-align: top; }
  .Rating_base--2lT32.Rating_isEmpty--28iwo {
    box-shadow: none;
    color: rgba(249, 245, 225, 0.3);
    text-shadow: none; }
  .Rating_base--2lT32.Rating_isLight--20KJS {
    box-shadow: none;
    background-color: transparent; }

.Rating_chart--o-9Zx {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin: 10px 0 0;
  transform: translate(-50%, -50%);
  pointer-events: none; }
