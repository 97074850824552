.ModVersionUploadForm_base--1A4Fa {
  padding: 4px 0 0; }

.ModVersionUploadForm_caption--1lV34 {
  position: relative;
  margin: 0 0 25px; }

.ModVersionUploadForm_cancel--18NeF {
  position: absolute;
  top: 1px;
  right: 0; }

.ModVersionUploadForm_row--BK4uC {
  display: flex;
  flex-flow: row nowrap; }
  .ModVersionUploadForm_row__fileUpload--3XTcN {
    align-items: center;
    margin-bottom: 30px; }
  .ModVersionUploadForm_row--BK4uC:not(.ModVersionUploadForm_row__fileUpload--3XTcN):not(:last-child) {
    margin: 0 0 20px; }

.ModVersionUploadForm_label--2mDWU {
  flex: 0 0 342px;
  box-sizing: border-box;
  margin: 4px 0 0;
  padding: 0 40px 0 0; }

.ModVersionUploadForm_field--21WO2 {
  flex: 1 1 100%; }

.ModVersionUploadForm_select--1_52F {
  width: 275px; }

.ModVersionUploadForm_fileUploadSummary--3zrcV {
  margin: 0 20px 0 0; }

.ModVersionUploadForm_divider--2sNiz {
  margin: 30px 0; }
