.DialogDownload_base--x-LD5 {
  width: 565px; }

.DialogDownload_modVersion--2tYnS {
  padding: 8px 0 0; }

.DialogDownload_select--3ZUVG {
  width: 350px;
  margin: 14px 0 18px; }

.DialogDownload_hr--3RrFi {
  width: 100%;
  height: 1px;
  margin: 17px 0 15px;
  border: none;
  background-color: #2b2b2c; }

.DialogDownload_attention--17Z6n {
  margin: 0 0 35px;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px; }
  .DialogDownload_attention--17Z6n mark {
    background: none;
    color: #fefbed; }

.DialogDownload_hidden--RCW-c {
  display: none; }

.DialogDownload_clampLines--2CJEP {
  word-wrap: break-word; }
