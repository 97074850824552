.FooterLinks_base--3qlwC {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 8px 40px;
  text-align: left; }

.FooterLinks_link--3wfJW {
  position: relative;
  color: #9a9997;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s; }
  .FooterLinks_link--3wfJW:hover {
    color: #e9e3be; }

.FooterLinks_externalLinkIcon--1fJV1 {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-left: 4px;
  fill: currentColor; }
