.Error_base--aqyP3 {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  color: #e9e3be;
  font-size: 20px; }
  .Error_base--aqyP3.Error_isSmall--1pz1w {
    height: 195px;
    padding: 0 0 33px; }
