.Paginator_base--2Mn58 {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #e9e2be;
  vertical-align: top;
  user-select: none; }

.Paginator_page--vnlx7 {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 6px;
  border: 1px solid rgba(228, 222, 185, 0.2);
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
  background-image: none;
  background-repeat: repeat-x;
  background-position: center;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer;
  transition: border-color 0.4s; }
  .Paginator_page--vnlx7:hover {
    border: 1px solid #e4deb9; }
  .Paginator_page--vnlx7.Paginator_isActive--1ioba {
    box-shadow: none;
    background-color: #e4deb9;
    color: #000;
    cursor: default; }

.Paginator_prev--3Iopa,
.Paginator_next--35EUa {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  transition: border-color 0.4s; }
  .Paginator_prev--3Iopa.Paginator_isDisabled--1KEAn,
  .Paginator_next--35EUa.Paginator_isDisabled--1KEAn {
    cursor: default; }
  .Paginator_prev--3Iopa:not(.Paginator_isDisabled--1KEAn):hover,
  .Paginator_next--35EUa:not(.Paginator_isDisabled--1KEAn):hover {
    border: 1px solid #e4deb9; }
  .Paginator_prev--3Iopa::before,
  .Paginator_next--35EUa::before {
    position: absolute;
    top: 12px;
    padding: 3px;
    border: solid rgba(228, 222, 185, 0.2);
    border-width: 0 2px 2px 0;
    line-height: 32px;
    content: '';
    transition: border-color 0.4s; }
  .Paginator_prev--3Iopa:not(.Paginator_isDisabled--1KEAn):hover::before,
  .Paginator_next--35EUa:not(.Paginator_isDisabled--1KEAn):hover::before {
    border: solid #e4deb9;
    border-width: 0 2px 2px 0; }

.Paginator_prev--3Iopa {
  margin: 0 6px 0 0; }
  .Paginator_prev--3Iopa::before {
    left: 13px;
    transform: rotate(135deg); }

.Paginator_next--35EUa {
  margin: 0 0 0 6px; }
  .Paginator_next--35EUa::before {
    right: 13px;
    transform: rotate(-45deg); }

.Paginator_hellip--392yR {
  position: relative;
  top: 20px;
  display: inline-block;
  margin: 0 2px;
  font-size: 16px;
  vertical-align: top; }
