.SubscriptionsItem_base--2DfrH {
  display: flex;
  height: 85px;
  overflow: hidden;
  border-radius: 10px;
  background: #050505; }
  .SubscriptionsItem_base--2DfrH:not(:last-child) {
    margin: 0 0 20px; }

.SubscriptionsItem_mod--2UwXN {
  display: flex;
  flex: 0 0 588px;
  overflow: hidden;
  white-space: normal; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_mod--2UwXN {
      flex: 0 0 450px; } }

.SubscriptionsItem_cover--1eJcn {
  position: relative;
  flex: 0 0 151px;
  height: 85px;
  overflow: hidden;
  background: url(images/cover.jpg) center/contain no-repeat;
  cursor: pointer;
  transition: opacity 0.4s; }
  .SubscriptionsItem_cover--1eJcn::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 49px;
    height: 85px;
    background: linear-gradient(to left, #050505 0%, rgba(5, 5, 5, 0) 100%);
    content: ''; }
  .SubscriptionsItem_mod--2UwXN:hover .SubscriptionsItem_cover--1eJcn {
    opacity: .4; }

.SubscriptionsItem_title--11Sql {
  display: flex;
  flex: 0 0 437px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px 0 27px;
  overflow: hidden;
  color: #e9e3be;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  transition: color 0.4s; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_title--11Sql {
      flex: 0 0 299px; } }
  .SubscriptionsItem_mod--2UwXN:hover .SubscriptionsItem_title--11Sql {
    color: #f9b723; }

.SubscriptionsItem_clampLines--1Ql12 {
  width: 100%;
  overflow: hidden;
  word-wrap: break-word; }

.SubscriptionsItem_hidden--2KJSg {
  display: none; }

.SubscriptionsItem_rate--2p3G- {
  display: flex;
  flex: 1 1 112px;
  align-items: center; }

.SubscriptionsItem_modData--1rpPg {
  display: flex;
  flex: 1 1 324px;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  color: #a29e87;
  font-size: 11px;
  text-transform: uppercase; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_modData--1rpPg {
      flex: 1 0 150px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 20px; } }

.SubscriptionsItem_modDataItem--1kP72 {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_modDataItem--1kP72 {
      width: 100%;
      padding: 6px 0; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .SubscriptionsItem_modDataItem--1kP72 {
      margin: 0 20px 0 0; } }
  @media all and (min-width: 1408px) {
    .SubscriptionsItem_modDataItem--1kP72 {
      margin: 0 20px 0 0; } }
  .SubscriptionsItem_modDataItem--1kP72 mark {
    background: none;
    color: #f9f5e1;
    font-weight: 700; }
  .SubscriptionsItem_modDataItem--1kP72.SubscriptionsItem_isNew--3XEmr {
    position: relative;
    padding: 0 0 0 20px;
    color: #f9b723; }
    @media all and (max-width: 1365px) {
      .SubscriptionsItem_modDataItem--1kP72.SubscriptionsItem_isNew--3XEmr {
        padding: 6px 0 6px 20px; } }
    .SubscriptionsItem_modDataItem--1kP72.SubscriptionsItem_isNew--3XEmr::before {
      position: absolute;
      top: 50%;
      left: -6px;
      width: 30px;
      height: 30px;
      background: url(images/alert.png) center no-repeat;
      content: '';
      transform: translateY(-50%); }
    .SubscriptionsItem_modDataItem--1kP72.SubscriptionsItem_isNew--3XEmr mark {
      color: #f9b723; }

.SubscriptionsItem_download--2brcn {
  display: flex;
  flex: 1 1 200px;
  align-items: center;
  box-sizing: border-box; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_download--2brcn {
      padding: 0 10px 0 0; } }

.SubscriptionsItem_downloadButton--QtaVr {
  width: 180px; }

.SubscriptionsItem_buttons--_7PT3 {
  display: flex;
  flex: 0 0 104px;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 10px 0 0; }
  @media all and (max-width: 1365px) {
    .SubscriptionsItem_buttons--_7PT3 {
      flex: 0 0 94px; } }

.SubscriptionsItem_button--1Xhpk {
  margin: 0 10px 0 0; }
