.Cancel_base--2G7mN {
  position: relative;
  display: inline-block;
  width: 21px;
  height: 21px;
  color: #858271;
  font-size: 11px;
  line-height: 21px;
  text-transform: uppercase;
  vertical-align: top;
  cursor: pointer;
  transition: color 0.4s; }
  .Cancel_base--2G7mN::before, .Cancel_base--2G7mN::after {
    position: absolute;
    top: 0;
    width: 19px;
    height: 19px;
    border: 1px solid rgba(228, 222, 185, 0.3);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .Cancel_base--2G7mN::before {
    background-image: url(images/cancel.svg);
    opacity: 1; }
  .Cancel_base--2G7mN::after {
    border-color: #e9e3be;
    background-color: #e9e3be;
    background-image: url(images/cancel-hover.svg);
    opacity: 0; }
  .Cancel_base--2G7mN:hover {
    color: #e9e3be; }
    .Cancel_base--2G7mN:hover::before {
      opacity: 0; }
    .Cancel_base--2G7mN:hover::after {
      opacity: 1; }
  .Cancel_base__left--Aj0v5, .Cancel_base__right--2su_s {
    width: auto; }
  .Cancel_base__left--Aj0v5 {
    padding: 0 26px 0 0; }
    .Cancel_base__left--Aj0v5::before, .Cancel_base__left--Aj0v5::after {
      right: 0; }
  .Cancel_base__right--2su_s {
    padding: 0 0 0 26px; }
    .Cancel_base__right--2su_s::before, .Cancel_base__right--2su_s::after {
      left: 0; }
