.Header_base--2zAPF {
  position: relative;
  box-sizing: border-box;
  height: 590px;
  background: url(images/header-bg.jpg) bottom center no-repeat/cover; }
  @media all and (max-width: 1365px) {
    .Header_base--2zAPF {
      padding: 41px 0 0; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_base--2zAPF {
      padding: 24px 0 0; } }
  @media all and (min-width: 1408px) {
    .Header_base--2zAPF {
      padding: 24px 0 0; } }

.Header_inner--2Jnof {
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto; }
  @media all and (max-width: 1365px) {
    .Header_inner--2Jnof {
      width: 1022px;
      padding: 0 28px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_inner--2Jnof {
      width: 1366px;
      padding: 0 40px; } }
  @media all and (min-width: 1408px) {
    .Header_inner--2Jnof {
      width: 1408px;
      padding: 0 40px; } }

.Header_logo--3heik {
  display: block;
  background-repeat: no-repeat;
  background-position: center; }
  @media all and (max-width: 1365px) {
    .Header_logo--3heik {
      width: 133px;
      height: 140px;
      margin: 0 auto 28px;
      background-image: url(images/header-logo-small.png); } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_logo--3heik {
      width: 167px;
      height: 176px;
      margin: 0 auto 10px;
      background-image: url(images/header-logo-large.png); } }
  @media all and (min-width: 1408px) {
    .Header_logo--3heik {
      width: 167px;
      height: 176px;
      margin: 0 auto 10px;
      background-image: url(images/header-logo-large.png); } }
