.ModDetailsFixedBar_base--2-IcI {
  position: fixed;
  top: -50px;
  right: 0;
  left: 0;
  z-index: 995;
  height: 50px;
  background-color: #050505;
  opacity: 0;
  transition: top 0.4s, opacity 0.4s; }
  .ModDetailsFixedBar_base--2-IcI.ModDetailsFixedBar_isVisible--3--f- {
    top: 0;
    opacity: 1; }

.ModDetailsFixedBar_inner--26ppA {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto; }
  @media all and (max-width: 1365px) {
    .ModDetailsFixedBar_inner--26ppA {
      width: 1022px;
      padding: 0 28px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetailsFixedBar_inner--26ppA {
      width: 1366px;
      padding: 0 40px; } }
  @media all and (min-width: 1408px) {
    .ModDetailsFixedBar_inner--26ppA {
      width: 1408px;
      padding: 0 40px; } }

.ModDetailsFixedBar_name--SpcD9 {
  position: relative;
  top: -2px;
  margin: 0 40px 0 0;
  overflow: hidden;
  color: #e9e3be;
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* stylelint-disable */
_:-ms-lang(x), .ModDetailsFixedBar_name--SpcD9 {
  max-width: 50%; }
  @media all and (max-width: 1365px) {
    _:-ms-lang(x), .ModDetailsFixedBar_name--SpcD9 {
      max-width: 40%; } }

/* stylelint-enable */
.ModDetailsFixedBar_details--1TBXr {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.ModDetailsFixedBar_label--2QiqQ {
  color: #a29e87;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap; }
  .ModDetailsFixedBar_label--2QiqQ:not(:last-child) {
    margin-right: 28px; }
  .ModDetailsFixedBar_label--2QiqQ mark {
    background: transparent;
    color: #f9f5e1;
    font-weight: 700; }
  .ModDetailsFixedBar_label--2QiqQ i {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; }
