.Subscriptions_base--1AX3p {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;
  padding: 0 0 70px; }

.Subscriptions_head--x-Tsh {
  flex: 0 0 auto; }

.Subscriptions_hidden--22k3_ {
  display: none; }

.Subscriptions_title--GrK7v.Subscriptions_isMuted--PSZ9D mark {
  color: #434343; }

.Subscriptions_body--34c_f {
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */
  margin: 20px 0 -20px; }

.Subscriptions_divider--2ZNRG {
  margin: 20px 0; }

.Subscriptions_itemsHeader--3n31Z {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0 0 20px; }

.Subscriptions_ItemsButton--2lD3r {
  position: absolute;
  top: 0;
  right: 0;
  width: 350px; }
