.EditChangelog_base--2f6Xs {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 20px; }

.EditChangelog_label--2juv8 {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditChangelog_field--2ufAh {
  flex: 1 1 100%; }
