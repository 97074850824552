.LanguageSelectMini_base--_eXln {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 21px;
  border-radius: 10px;
  background-color: #050505;
  vertical-align: top;
  user-select: none; }

.LanguageSelectMini_item--26In- {
  width: 15px;
  height: 15px;
  margin: 0 3px;
  border-radius: 50%;
  background-image: url(images/flags.png);
  background-repeat: no-repeat;
  cursor: pointer;
  transition: box-shadow 0.4s; }
  .LanguageSelectMini_item--26In-:hover:not(.LanguageSelectMini_isChecked--30kWM) {
    box-shadow: inset 0 0 0 1px #e9e3be; }
  .LanguageSelectMini_item__ru--3JwdO {
    background-position: 0 0; }
  .LanguageSelectMini_item__en--3xr5H {
    background-position: 0 -15px; }
  .LanguageSelectMini_item--26In-.LanguageSelectMini_isChecked--30kWM {
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3), 0 0 0 3px #e9e3be;
    cursor: default; }
