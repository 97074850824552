.CreateMod_base--OGPPp {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; }

.CreateMod_head--2EteU {
  flex: 0 0 auto; }

.CreateMod_body--2WnFa {
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.CreateMod_inner--1dBf2 {
  position: relative;
  height: 100%; }

.CreateMod_content--21lKw {
  height: 100%; }
  @media all and (max-width: 1365px) {
    .CreateMod_content--21lKw {
      width: 100%; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .CreateMod_content--21lKw {
      width: 929px; } }
  @media all and (min-width: 1408px) {
    .CreateMod_content--21lKw {
      width: 929px; } }

.CreateMod_form--9MxoC {
  padding: 25px 0 0; }
  .CreateMod_form--9MxoC.CreateMod_isDisabled--2m5aH {
    opacity: .3;
    pointer-events: none; }

.CreateMod_divider--2FwS9 {
  margin: 30px 0; }

.CreateMod_info--TctY2 {
  padding: 17px 0;
  color: #b8b8a2;
  font-size: 16px;
  line-height: 36px; }

.CreateMod_notification--1BPSs:not(:last-child) {
  margin: 0 0 20px; }

.CreateMod_notificationContent--3phNt {
  position: relative;
  font-size: 14px;
  line-height: 20px; }
  .CreateMod_notificationContent--3phNt mark {
    background: transparent;
    color: #db2d1b;
    font-size: 20px;
    line-height: 20px; }
  .CreateMod_notificationContent--3phNt::before {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: url(images/create-notification-error.png) center no-repeat;
    vertical-align: top;
    content: ''; }
  .CreateMod_notificationContent__info--kZ0sG::before {
    background-color: #395eff; }
  .CreateMod_notificationContent__warning--1oTXA::before {
    background-color: #db2d1b; }

.CreateMod_notificationBlockedUser--3ckEL {
  margin: 48px 0 17px; }

.CreateMod_attentions--2Aojf {
  margin: 37px 0 55px; }

.CreateMod_attention--2FmJ1 {
  margin: 20px 0;
  color: #b8b8a2;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px; }

.CreateMod_button--3Hkk0 {
  display: inline-block;
  vertical-align: top; }
  .CreateMod_button--3Hkk0:not(:last-child) {
    margin: 0 30px 0 0; }
