.EditChangelogListItem_item--1U5EV {
  position: relative;
  margin: 0 0 0 342px;
  border-radius: 10px;
  box-shadow: none;
  background-color: rgba(249, 245, 225, 0.04);
  word-wrap: break-word;
  transition: background-color 0.4s, box-shadow 0.4s; }
  @media all and (max-width: 1365px) {
    .EditChangelogListItem_item--1U5EV {
      width: 624px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .EditChangelogListItem_item--1U5EV {
      width: 587px; } }
  @media all and (min-width: 1408px) {
    .EditChangelogListItem_item--1U5EV {
      width: 587px; } }
  .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF {
    cursor: move; }
    .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-radius: 10px;
      background: url(images/edit-changelog-list-item-hover.png) center no-repeat;
      opacity: 0;
      content: '';
      transition: opacity 0.4s;
      pointer-events: none; }
    .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF:hover {
      background-color: #333232; }
      .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF:hover::after {
        opacity: 1; }
    .dragged .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF {
      box-shadow: 0 0 0 1px #f9b723, 0px 0px 40px 0px rgba(255, 84, 0, 0.3);
      background-color: #333232; }
      .dragged .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF::after {
        opacity: 1; }

.EditChangelogListItem_inner--uF81x {
  padding: 17px 0;
  opacity: 1;
  transition: opacity 0.4s; }
  .dragged .EditChangelogListItem_inner--uF81x,
  .EditChangelogListItem_item--1U5EV.EditChangelogListItem_isDraggable--3pEkF:hover .EditChangelogListItem_inner--uF81x {
    opacity: .3; }

.EditChangelogListItem_title--U27ve {
  margin: 0 20px 12px;
  color: #f9f5e1;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px; }

.EditChangelogListItem_content--25o1q {
  margin: 0 20px;
  color: #b8b8a2;
  font-size: 14px;
  line-height: 18px; }

.EditChangelogListItem_previewRemove--2Unut {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 3; }

.EditChangelogListItem_previewEdit--UYfTe {
  position: absolute;
  top: -10px;
  right: 21px;
  z-index: 2; }
