.ActionReport_base--3k95V {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  color: #db2d1b;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionReport_base--3k95V::before, .ActionReport_base--3k95V::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #db2d1b;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: background-color 0.4s, opacity 0.4s; }
  .ActionReport_base--3k95V::before {
    background-image: url(images/action-report.svg);
    opacity: 1; }
  .ActionReport_base--3k95V::after {
    background-image: url(images/action-report-hover.svg);
    opacity: 0; }
  .ActionReport_base--3k95V:hover:not(.ActionReport_isDisabled--3VI0m):not(.ActionReport_isCompleted--2m9va) {
    color: #ff482a; }
    .ActionReport_base--3k95V:hover:not(.ActionReport_isDisabled--3VI0m):not(.ActionReport_isCompleted--2m9va)::before {
      opacity: 0; }
    .ActionReport_base--3k95V:hover:not(.ActionReport_isDisabled--3VI0m):not(.ActionReport_isCompleted--2m9va)::after {
      opacity: 1; }
    .ActionReport_base--3k95V:hover:not(.ActionReport_isDisabled--3VI0m):not(.ActionReport_isCompleted--2m9va)::before, .ActionReport_base--3k95V:hover:not(.ActionReport_isDisabled--3VI0m):not(.ActionReport_isCompleted--2m9va)::after {
      background-color: #db2d1b; }
  .ActionReport_base--3k95V.ActionReport_isCompleted--2m9va:not(.ActionReport_isDisabled--3VI0m) {
    opacity: .3;
    cursor: default; }
    .ActionReport_base--3k95V.ActionReport_isCompleted--2m9va:not(.ActionReport_isDisabled--3VI0m)::before {
      opacity: 0; }
    .ActionReport_base--3k95V.ActionReport_isCompleted--2m9va:not(.ActionReport_isDisabled--3VI0m)::after {
      opacity: 1; }
    .ActionReport_base--3k95V.ActionReport_isCompleted--2m9va:not(.ActionReport_isDisabled--3VI0m)::before, .ActionReport_base--3k95V.ActionReport_isCompleted--2m9va:not(.ActionReport_isDisabled--3VI0m)::after {
      border-color: #db2d1b;
      background-color: #db2d1b; }
  .ActionReport_base--3k95V.ActionReport_isDisabled--3VI0m {
    width: 50px;
    padding: 0;
    opacity: .3;
    cursor: default; }
