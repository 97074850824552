.FilterGameVersion_base--1cXn- {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  border-radius: 25px; }
  .FilterGameVersion_base--1cXn-.FilterGameVersion_isDisabled--gQqx6 {
    opacity: .3;
    pointer-events: none; }

.FilterGameVersion_select--2sce2 {
  min-width: 130px; }
