.ActionMuteSubscribe_base--1_HCP {
  position: relative;
  width: 32px;
  height: 32px;
  border: 1px solid #f9b723;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.4s; }
  .ActionMuteSubscribe_base--1_HCP::before, .ActionMuteSubscribe_base--1_HCP::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .ActionMuteSubscribe_base--1_HCP::before {
    background-image: url(images/action-mute-subscribe.png);
    opacity: 1; }
  .ActionMuteSubscribe_base--1_HCP::after {
    background-image: url(images/action-mute-subscribe-hover.png);
    opacity: 0; }
  .ActionMuteSubscribe_base--1_HCP:hover {
    background-color: #f9b723; }
    .ActionMuteSubscribe_base--1_HCP:hover::before {
      opacity: 0; }
    .ActionMuteSubscribe_base--1_HCP:hover::after {
      opacity: 1; }
