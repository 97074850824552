.DialogLogin_base--EvS7I {
  width: 565px; }

.DialogLogin_realms--3oFe0 {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 -25px 0;
  padding: 13px 0 0; }

.DialogLogin_realm--2ydSk {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 267px;
  height: 50px;
  margin: 0 0 30px;
  padding: 0 40px;
  background-repeat: no-repeat;
  background-position: center;
  color: #fefbed;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .DialogLogin_realm--2ydSk::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 25px;
    background-color: rgba(28, 28, 30, 0.1);
    content: '';
    transition: background-color 0.4s; }
  .DialogLogin_realm--2ydSk:hover::after {
    background-color: rgba(28, 28, 30, 0.6); }
  .DialogLogin_realm__ru--2fTlv {
    background-image: url(images/dialog-login-ru.png); }
  .DialogLogin_realm__eu--arEHE {
    background-image: url(images/dialog-login-eu.png); }
  .DialogLogin_realm__us--1QFOD {
    background-image: url(images/dialog-login-us.png); }
  .DialogLogin_realm__sg--lu7Bc {
    background-image: url(images/dialog-login-sg.png); }
