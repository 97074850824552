.EditLanguageForm_base--3b-8e {
  margin: 40px 0; }

.EditLanguageForm_caption--nOzWn {
  position: relative;
  margin: 0 0 25px; }

.EditLanguageForm_cancel--2boSY {
  position: absolute;
  top: 1px;
  right: 0; }

.EditLanguageForm_row--1ImaP {
  display: flex;
  flex-flow: row nowrap; }
  .EditLanguageForm_row--1ImaP:not(:last-child) {
    margin: 0 0 20px; }

.EditLanguageForm_label--2xwro {
  flex: 0 0 342px;
  box-sizing: border-box;
  margin: 4px 0 0;
  padding: 0 40px 0 0; }

/* stylelint-disable */
_:-ms-lang(x), .EditLanguageForm_label--2xwro {
  flex: 0 0 302px; }

/* stylelint-enable */
.EditLanguageForm_field--z5b-X {
  flex: 1 1 100%;
  overflow: hidden; }
  .EditLanguageForm_field__editor--PLeGE {
    overflow: visible; }

.EditLanguageForm_divider--2UTea {
  margin: 40px 0; }

.EditLanguageForm_lang--2V9-7 {
  position: relative;
  display: inline-block;
  padding: 0 0 0 28px;
  color: #f9f5e1;
  font-size: 18px; }
  .EditLanguageForm_lang--2V9-7::before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); }
  .EditLanguageForm_lang__ru--7iblJ::before {
    background: url(images/flags.png) 0 0 no-repeat;
    content: ''; }
  .EditLanguageForm_lang__en--Zrmdy::before {
    background: url(images/flags.png) 0 -15px no-repeat;
    content: ''; }
