.TitleMinor_base--1yIF9 {
  position: relative;
  color: #e9e3be;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase; }
  .TitleMinor_base--1yIF9 mark {
    background: transparent;
    color: #fab81b; }
  .TitleMinor_base--1yIF9 sup {
    color: #726f5f;
    font-size: 15px;
    vertical-align: text-top; }
