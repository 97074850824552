.Toggle_base--16-Sw {
  display: inline-block;
  vertical-align: top; }
  .Toggle_base--16-Sw.Toggle_isDisabled--1N8CP {
    opacity: .3;
    pointer-events: none; }

.Toggle_toggle--2xAs8 {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  height: 21px;
  border-radius: 10px;
  background-color: #050505;
  user-select: none; }

.Toggle_item--Oqqbm {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 118px;
  height: 100%;
  padding: 0 18px;
  border-radius: 10px;
  color: #858271;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s; }
  .Toggle_item--Oqqbm:hover:not(.Toggle_isChecked--2WHO3) {
    color: #e9e3be; }
  .Toggle_item--Oqqbm.Toggle_isChecked--2WHO3 {
    background-color: #e9e3be;
    color: #050505;
    cursor: default; }
