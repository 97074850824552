.ActionVisibleMod_base--3h5ki {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionVisibleMod_base--3h5ki::before, .ActionVisibleMod_base--3h5ki::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #f9b723;
    border-radius: 50%;
    background-color: #f9b723;
    background-image: url(images/action-visible-mod.png);
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: background-color 0.4s; }
  .ActionVisibleMod_base--3h5ki:hover:not(.ActionVisibleMod_isDisabled--2rCIW) {
    color: #ffd428; }
    .ActionVisibleMod_base--3h5ki:hover:not(.ActionVisibleMod_isDisabled--2rCIW)::before, .ActionVisibleMod_base--3h5ki:hover:not(.ActionVisibleMod_isDisabled--2rCIW)::after {
      background-color: #ffd428; }
