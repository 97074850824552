.PreviewRemove_base--1WVM- {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #050505;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer; }
  .PreviewRemove_base--1WVM-::before, .PreviewRemove_base--1WVM-::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .PreviewRemove_base--1WVM-::before {
    background-image: url(images/preview-remove.svg);
    opacity: 1; }
  .PreviewRemove_base--1WVM-::after {
    background-image: url(images/preview-remove-hover.svg);
    opacity: 0; }
  .PreviewRemove_base--1WVM-:hover::before {
    opacity: 0; }
  .PreviewRemove_base--1WVM-:hover::after {
    opacity: 1; }
