.Main_base--3GPcx {
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto; }
  @media all and (max-width: 1365px) {
    .Main_base--3GPcx {
      width: 1022px;
      padding: 0 28px 50px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Main_base--3GPcx {
      width: 1366px;
      padding: 30px 40px 50px; } }
  @media all and (min-width: 1408px) {
    .Main_base--3GPcx {
      width: 1408px;
      padding: 30px 40px 50px; } }
