.Voting_base--1IYSN {
  position: relative;
  width: 302px;
  height: 229px;
  text-align: center; }

.Voting_body--1n70B {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 214px;
  height: 214px;
  border-radius: 50%;
  vertical-align: top; }
  .Voting_body--1n70B::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 258px;
    height: 258px;
    background-image: url(images/voting-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transform: translate(-50%, -50%); }
    .Voting_base--1IYSN.Voting_isEmpty--2T6ZQ .Voting_body--1n70B::after {
      width: 214px;
      height: 214px;
      border-radius: 50%;
      background: rgba(23, 3, 3, 0.3); }

.Voting_chart--3xCCo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin: 10px 0 0;
  transform: translate(-50%, -50%);
  pointer-events: none; }

.Voting_rating--2AfcU {
  position: relative;
  top: -6px; }
  .Voting_rating--2AfcU::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 217px;
    height: 165px;
    background-image: url(images/voting-glow.png);
    content: '';
    transform: translate(-50%, -50%); }
    .Voting_base--1IYSN.Voting_isEmpty--2T6ZQ .Voting_rating--2AfcU::after {
      content: normal; }

.Voting_ratingLabel--39YGP {
  color: #a29e87;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase; }

.Voting_ratingValue--2injV {
  position: relative;
  z-index: 3;
  color: #fab81b;
  font-size: 70px;
  font-weight: 700;
  line-height: 1; }
  .Voting_base--1IYSN.Voting_isEmpty--2T6ZQ .Voting_ratingValue--2injV {
    color: rgba(249, 245, 225, 0.1); }

.Voting_vote--35iXx {
  position: absolute;
  right: 0;
  bottom: 23px;
  left: 0;
  z-index: 5;
  color: #f9f5e1;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase; }

.Voting_footer--2YqPZ {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 25px;
  background-color: #050505;
  color: #a29e87;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase; }

.Voting_button--DCcAd {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.4s; }
  .Voting_button--DCcAd::before, .Voting_button--DCcAd::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; }
  .Voting_button--DCcAd::before {
    opacity: 1; }
  .Voting_button--DCcAd::after {
    opacity: 0; }
  .Voting_button__dislike--YhqUM {
    left: -1px;
    border: 1px solid #db2d1b; }
    .Voting_button__dislike--YhqUM::before {
      background-image: url(images/voting-dislike.png);
      opacity: 1; }
    .Voting_button__dislike--YhqUM::after {
      background-image: url(images/voting-dislike-hover.png);
      opacity: 0; }
    .Voting_button__dislike--YhqUM.Voting_isActive--UMOBj, .Voting_button__dislike--YhqUM:not(.Voting_isDisabled--2x7lm):hover {
      background-color: #db2d1b; }
      .Voting_button__dislike--YhqUM.Voting_isActive--UMOBj::before, .Voting_button__dislike--YhqUM:not(.Voting_isDisabled--2x7lm):hover::before {
        opacity: 0; }
      .Voting_button__dislike--YhqUM.Voting_isActive--UMOBj::after, .Voting_button__dislike--YhqUM:not(.Voting_isDisabled--2x7lm):hover::after {
        opacity: 1; }
  .Voting_button__like--1LWyT {
    right: -1px;
    border: 1px solid #f9b723; }
    .Voting_button__like--1LWyT::before {
      background-image: url(images/voting-slike.png);
      opacity: 1; }
    .Voting_button__like--1LWyT::after {
      background-image: url(images/voting-slike-hover.png);
      opacity: 0; }
    .Voting_button__like--1LWyT.Voting_isActive--UMOBj, .Voting_button__like--1LWyT:not(.Voting_isDisabled--2x7lm):hover {
      background-color: #f9b723; }
      .Voting_button__like--1LWyT.Voting_isActive--UMOBj::before, .Voting_button__like--1LWyT:not(.Voting_isDisabled--2x7lm):hover::before {
        opacity: 0; }
      .Voting_button__like--1LWyT.Voting_isActive--UMOBj::after, .Voting_button__like--1LWyT:not(.Voting_isDisabled--2x7lm):hover::after {
        opacity: 1; }
  .Voting_button--DCcAd.Voting_isDisabled--2x7lm {
    cursor: default; }
