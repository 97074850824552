.ActionRemoveMod_base--1Vi7P {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 60px 0 0;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionRemoveMod_base--1Vi7P::before, .ActionRemoveMod_base--1Vi7P::after {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #f9b723;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; }
  .ActionRemoveMod_base--1Vi7P::before {
    background-image: url(images/action-remove-mod.png);
    opacity: 1; }
  .ActionRemoveMod_base--1Vi7P::after {
    background-image: url(images/action-remove-mod-hover.png);
    opacity: 0; }
  .ActionRemoveMod_base--1Vi7P:hover:not(.ActionRemoveMod_isDisabled--R360o) {
    color: #ffd428; }
    .ActionRemoveMod_base--1Vi7P:hover:not(.ActionRemoveMod_isDisabled--R360o)::before {
      opacity: 0; }
    .ActionRemoveMod_base--1Vi7P:hover:not(.ActionRemoveMod_isDisabled--R360o)::after {
      opacity: 1; }
    .ActionRemoveMod_base--1Vi7P:hover:not(.ActionRemoveMod_isDisabled--R360o)::before, .ActionRemoveMod_base--1Vi7P:hover:not(.ActionRemoveMod_isDisabled--R360o)::after {
      border-color: #f9b723;
      background-color: #f9b723; }
