.ActionHiddenMod_base--3eytH {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0 0 0 60px;
  color: #f9b723;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.4s; }
  .ActionHiddenMod_base--3eytH::before, .ActionHiddenMod_base--3eytH::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid #f9b723;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; }
  .ActionHiddenMod_base--3eytH::before {
    background-image: url(images/action-hidden-mod.png);
    opacity: 1; }
  .ActionHiddenMod_base--3eytH::after {
    background-image: url(images/action-hidden-mod-hover.png);
    opacity: 0; }
  .ActionHiddenMod_base--3eytH:hover:not(.ActionHiddenMod_isDisabled--3Q__7) {
    color: #ffd428; }
    .ActionHiddenMod_base--3eytH:hover:not(.ActionHiddenMod_isDisabled--3Q__7)::before {
      opacity: 0; }
    .ActionHiddenMod_base--3eytH:hover:not(.ActionHiddenMod_isDisabled--3Q__7)::after {
      opacity: 1; }
    .ActionHiddenMod_base--3eytH:hover:not(.ActionHiddenMod_isDisabled--3Q__7)::before, .ActionHiddenMod_base--3eytH:hover:not(.ActionHiddenMod_isDisabled--3Q__7)::after {
      border-color: #f9b723;
      background-color: #f9b723; }
