.Error404_base--2aZ5A {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%; }

.Error404_inner--eptFX {
  text-align: center; }

.Error404_error--1gsPv {
  width: 376px;
  height: 157px;
  margin: 0 0 50px;
  background: url(images/error-404.png) center no-repeat; }

.Error404_title--1sAAm {
  margin: 0 0 15px;
  color: #e9e3be;
  font-size: 44px;
  font-weight: 700;
  line-height: 1; }

.Error404_content--2AQ6S {
  margin: 0 0 34px;
  color: #e9e3be;
  font-size: 20px;
  line-height: 28px; }
