.TitleDialog_base--2SEvD {
  width: 100%;
  margin: 0 0 20px;
  color: #e9e3be;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px; }
  .TitleDialog_base--2SEvD mark {
    background-color: transparent;
    color: #f9b723; }
