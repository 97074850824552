.Footer_base--2epKI {
  display: flex;
  align-items: center;
  min-height: 90px;
  background-color: #111010;
  user-select: none; }

.Footer_inner--2uAgG {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
  gap: 30px; }
  @media all and (max-width: 1365px) {
    .Footer_inner--2uAgG {
      width: 1022px;
      padding-right: 10px;
      padding-left: 10px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Footer_inner--2uAgG {
      width: 1366px;
      padding-right: 40px;
      padding-left: 40px; } }
  @media all and (min-width: 1408px) {
    .Footer_inner--2uAgG {
      width: 1408px;
      padding: 0 40px; } }

.Footer_logo--2VG2- {
  display: inline-block;
  width: 79px;
  height: 21px;
  background: url(images/footer-logo.png) center no-repeat;
  vertical-align: top; }

.Footer_links--3N8hH {
  margin: 0 auto; }

.Footer_languageSelectCaption--1CwRa {
  margin-right: 7px;
  color: #e9e3be;
  font-size: 11px;
  text-transform: uppercase;
  white-space: nowrap; }
  @media all and (max-width: 1365px) {
    .Footer_languageSelectCaption--1CwRa {
      display: none; } }

.Footer_languageSelect--1G_vN {
  display: flex;
  align-items: center; }
