.ButtonYellowSmall_base--1u4b5 {
  height: 32px;
  padding: 0 30px;
  border: none;
  border-radius: 16px;
  background-color: #f9b723;
  color: #000;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s; }
  .ButtonYellowSmall_base--1u4b5:focus {
    outline: none; }
  .ButtonYellowSmall_base--1u4b5:hover {
    background-color: #ffd428; }
  .ButtonYellowSmall_base--1u4b5.ButtonYellowSmall_isWide--10Hyk {
    width: 100%; }
  .ButtonYellowSmall_base__plus--2BlKZ {
    position: relative;
    padding-left: 48px; }
    .ButtonYellowSmall_base__plus--2BlKZ::before {
      position: absolute;
      top: 50%;
      left: 30px;
      width: 10px;
      height: 10px;
      background: url(images/button-yellow-small-plus.svg) center no-repeat;
      content: '';
      transform: translateY(-50%); }
  .ButtonYellowSmall_base--1u4b5 mark {
    background: transparent;
    color: #805c0a; }
