.ButtonYellowLarge_base--1U4NR {
  height: 50px;
  padding: 0 50px;
  border: none;
  border-radius: 25px;
  background-color: #f9b723;
  color: #000;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s; }
  .ButtonYellowLarge_base--1U4NR:focus {
    outline: none; }
  .ButtonYellowLarge_base--1U4NR:hover:not(.ButtonYellowLarge_isDisabled--2sokE) {
    background-color: #ffd428; }
  .ButtonYellowLarge_base--1U4NR.ButtonYellowLarge_isDisabled--2sokE {
    opacity: .3;
    cursor: default; }
  .ButtonYellowLarge_base--1U4NR mark {
    background: transparent;
    color: #805c0a; }
