.Gallery_base--2nfgv {
  height: 100%; }

.Gallery_section--1EMt2 {
  position: relative; }
  .Gallery_section--1EMt2:not(:last-child) {
    margin-bottom: 50px; }
  .Gallery_section__filtered--23apC {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; }

.Gallery_filteredHead--3nnnm {
  position: relative;
  z-index: 2;
  flex: 0 0 auto; }

.Gallery_filteredBody--1T2AJ {
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.Gallery_title--CoRUj {
  display: inline-block;
  margin: 0 0 38px;
  vertical-align: top; }

.Gallery_reset--1C3h4 {
  position: absolute;
  top: -1px;
  left: 100%;
  margin: 0 0 0 7px; }

.Gallery_filter--1cmKD {
  position: absolute;
  top: -2px;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start; }

.Gallery_toggle--JSuvE {
  margin: 0 30px 0 0; }

.Gallery_section__filtered--23apC .Gallery_inner--2_k8B {
  height: 100%; }

@media all and (max-width: 1365px) {
  .Gallery_inner--2_k8B {
    margin-right: -15px;
    margin-left: -15px; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .Gallery_inner--2_k8B {
    margin-right: -13px;
    margin-left: -13px; } }

@media all and (min-width: 1408px) {
  .Gallery_inner--2_k8B {
    margin-right: -20px;
    margin-left: -20px; } }

.Gallery_mods--2ehU3 {
  margin: 0 0 -50px; }

.Gallery_mod--35OHa {
  display: inline-block;
  vertical-align: top; }
  @media all and (max-width: 1365px) {
    .Gallery_mod--35OHa {
      margin: 0 15px 50px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .Gallery_mod--35OHa {
      margin: 0 13px 50px; } }
  @media all and (min-width: 1408px) {
    .Gallery_mod--35OHa {
      margin: 0 20px 50px; } }

.Gallery_button--3tBEt {
  margin: 40px 0 0;
  text-align: center; }
