.EditChangelogAdd_row--QfYbB {
  display: flex;
  flex-flow: row nowrap; }
  .EditChangelogAdd_row--QfYbB:not(:last-child) {
    margin: 0 0 20px; }

.EditChangelogAdd_label--qkjwa {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditChangelogAdd_field--3yuB9 {
  flex: 1 1 100%; }
