/* stylelint-disable selector-list-comma-newline-after  */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

article,
aside,
audio,
canvas,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
keygen,
menu,
nav,
output,
progress,
section,
source,
video {
  display: block;
  margin: 0; }

body {
  line-height: 1; }

ol,
ul {
  list-style-type: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: normal; }

/* remember to define focus styles! */
:focus {
  outline: 0; }

del {
  text-decoration: line-through; }

/* tables still need cellspacing='0' in the markup */
table {
  border-spacing: 0;
  border-collapse: collapse; }

td {
  vertical-align: top; }

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: none; }

input,
textarea,
button {
  font-family: inherit; }

button {
  margin: 0; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

::-ms-clear {
  display: none; }

input:-webkit-autofill {
  color: #000 !important; }

html {
  height: 100%; }

body {
  height: 100%;
  background-color: #1c1c1e;
  background-image: url(images/sparks.png);
  background-repeat: repeat-y;
  background-position: top center;
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; }

@media all and (max-width: 1365px) {
  html,
  body {
    min-width: 1022px; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  html,
  body {
    min-width: 1366px; } }

@media all and (min-width: 1408px) {
  html,
  body {
    min-width: 1408px; } }

a {
  color: #f9b723;
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.4s; }
  a:hover {
    color: #ffd428; }

#app {
  height: 100%; }

.scroll-off {
  overflow-y: hidden; }

.notification-container {
  position: fixed;
  bottom: 20px;
  left: 20px; }

.notification {
  box-sizing: border-box;
  width: 100%;
  margin: 15px 0 0;
  padding: 12px 27px;
  border-radius: 3px;
  background: #f9b723;
  color: #030303;
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
  white-space: normal;
  word-wrap: break-word;
  opacity: .9;
  cursor: pointer; }

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0); }

.notification-enter.notification-enter-active {
  transition: all .4s; }

.notification-enter.notification-enter-active,
.notification-leave {
  visibility: visible;
  transform: translateZ(0); }

.notification-leave.notification-leave-active {
  visibility: hidden;
  transition: all .4s;
  transform: translate3d(100%, 0, 0); }

#common_menu {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 996 !important; }
  #common_menu .cm-layout_content {
    box-sizing: border-box;
    padding: 0 15px; }
