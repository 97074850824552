.Notification_base--3TXMi {
  box-sizing: border-box;
  padding: 13px 20px;
  border-left: 2px solid transparent;
  border-radius: 0 10px 10px 0;
  background-color: rgba(249, 245, 225, 0.04);
  color: #b8b8a2;
  font-size: 15px;
  line-height: 24px; }
  .Notification_base--3TXMi strong {
    color: #e9e3be;
    font-weight: 700; }
  .Notification_base--3TXMi ol {
    list-style-position: inside;
    list-style-type: decimal; }
  .Notification_base__warning--SSuUp {
    border-color: #db2d1b; }
  .Notification_base__info--1FGgx {
    border-color: #395eff; }
