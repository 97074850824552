.EditCategories_base--2V3Nv {
  display: flex;
  flex-flow: row nowrap; }

.EditCategories_label--1mVyc {
  flex: 0 0 342px;
  margin: 4px 0 0; }

.EditCategories_field--1UDBk {
  flex: 1 1 100%; }

.EditCategories_tags--3Y6fj {
  margin: 0 -8px -20px 0; }

.EditCategories_tag--ypnyP {
  display: inline-block;
  margin: 0 8px 20px 0;
  vertical-align: top; }
