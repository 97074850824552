.Information_base--1N4eq {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.Information_inner--XvB_f {
  text-align: center; }

.Information_message--3znmA {
  margin: 0 0 37px;
  color: #e9e3be;
  font-size: 20px;
  line-height: 1; }
