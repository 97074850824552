.Checkbox_base--2Uv2H {
  position: relative;
  display: inline-block;
  cursor: pointer; }
  .Checkbox_base--2Uv2H.Checkbox_isDisabled--1DdHB {
    opacity: .3;
    pointer-events: none; }

.Checkbox_input--2aP4D {
  position: absolute;
  visibility: hidden;
  opacity: 0; }

.Checkbox_check--2Othy {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  border: 1px solid #f9b723;
  border-radius: 3px;
  vertical-align: middle;
  transition: background-color 0.4s; }
  .Checkbox_base--2Uv2H:hover .Checkbox_check--2Othy {
    background-color: rgba(255, 212, 40, 0.2); }
  .Checkbox_base--2Uv2H.Checkbox_isChecked--2D6Rc .Checkbox_check--2Othy {
    background-color: #f9b723; }
  .Checkbox_base--2Uv2H.Checkbox_isChecked--2D6Rc:hover .Checkbox_check--2Othy {
    background-color: #ffd428; }
  .Checkbox_check--2Othy::before {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;
    background: url(images/checkbox-check.png) center no-repeat;
    opacity: 0;
    content: '';
    transition: opacity 0.4s; }
  .Checkbox_base--2Uv2H.Checkbox_isChecked--2D6Rc .Checkbox_check--2Othy::before {
    opacity: 1; }

.Checkbox_caption--1ZT9s {
  color: #f9b723;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
  transition: color 0.4s; }
  .Checkbox_base--2Uv2H:hover .Checkbox_caption--1ZT9s {
    color: #ffd428; }
