.ModderSection_base--3Q0-l {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;
  padding: 0 0 30px; }

.ModderSection_head--wVD7V {
  position: relative;
  z-index: 2;
  flex: 0 0 auto; }

.ModderSection_body--3_9vm {
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  /* stylelint-disable order/properties-order */
  -ms-flex: 1 0 auto;
  /* stylelint-enable */ }

.ModderSection_header--8-29r {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 50px; }

.ModderSection_titleSeparator--3f3VO {
  margin: 0 2px 0 4px;
  opacity: .3; }

.ModderSection_muted--6i13x {
  color: #434343; }

.ModderSection_filter--kW8_- {
  margin: 7px 0 0;
  line-height: 1; }
  .ModderSection_filter--kW8_-.ModderSection_isDisabled--1reLv {
    opacity: .3;
    pointer-events: none; }

.ModderSection_select--1D4dU {
  display: inline-block;
  width: 200px; }

.ModderSection_content--1Z2ea {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  @media all and (max-width: 1365px) {
    .ModderSection_content--1Z2ea {
      margin: 0 -15px -50px; } }
  @media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModderSection_content--1Z2ea {
      margin: 0 -13px -50px; } }
  @media all and (min-width: 1408px) {
    .ModderSection_content--1Z2ea {
      margin: 0 -20px -50px; } }

@media all and (max-width: 1365px) {
  .ModderSection_mod--NstX9 {
    margin: 0 15px 50px; } }

@media all and (min-width: 1366px) and (max-width: 1407px) {
  .ModderSection_mod--NstX9 {
    margin: 0 13px 50px; } }

@media all and (min-width: 1408px) {
  .ModderSection_mod--NstX9 {
    margin: 0 20px 50px; } }

.ModderSection_notification---G87h {
  margin: 0 0 60px; }
