.Select_base--9_QBo {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 32px;
  vertical-align: top;
  user-select: none; }
  .Select_base--9_QBo.Select_isDisabled--3d8Dp {
    opacity: .5;
    pointer-events: none; }

.Select_value--2jdOX {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 40px 0 20px;
  border-radius: 16px;
  background-color: rgba(5, 5, 5, 0.4);
  color: #e9e3be;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.4s; }
  .Select_value--2jdOX::after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 9px;
    height: 6px;
    background-image: url(images/select-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    transform: translateY(-50%); }
  .Select_value--2jdOX.Select_isOpened--1uZzY {
    border-radius: 16px 16px 0 0; }
    .Select_value--2jdOX.Select_isOpened--1uZzY::after {
      background-position: 0 -6px; }
  .Select_value--2jdOX.Select_isOpened--1uZzY, .Select_value--2jdOX:hover {
    background-color: #050505; }
  .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_value--2jdOX {
    position: relative;
    padding-left: 42px; }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_value--2jdOX::before {
      position: absolute;
      top: 8px;
      left: 19px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_value__ru--2c7xj::before {
      background: url(images/flags.png) 0 0 no-repeat;
      content: ''; }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_value__en--nGw56::before {
      background: url(images/flags.png) 0 -15px no-repeat;
      content: ''; }

.Select_dropdown--3TPHj {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  max-height: 160px;
  border-radius: 0 0 16px 16px;
  background-color: #050505; }
  .Select_base--9_QBo.Select_isOpened--1uZzY .Select_dropdown--3TPHj {
    display: block; }

.Select_option--1Cu8l {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 20px;
  border-radius: 16px;
  color: #858271;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s; }
  .Select_option--1Cu8l:hover {
    background-color: rgba(255, 255, 255, 0.06);
    color: #e9e3be; }
  .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_option--1Cu8l {
    position: relative;
    padding-left: 42px; }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_option--1Cu8l::before {
      position: absolute;
      top: 8px;
      left: 19px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_option__ru--1heWh::before {
      background: url(images/flags.png) 0 0 no-repeat;
      content: ''; }
    .Select_base--9_QBo.Select_isLanguage--Il1nR .Select_option__en--23eh6::before {
      background: url(images/flags.png) 0 -15px no-repeat;
      content: ''; }
